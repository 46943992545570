import React, { useEffect, useState } from 'react'
import QuestionTitle from '../QuestionTitle/QuestionTitle'
import { Paper, Button, Stack, Grid, IconButton, TextField, Autocomplete, Divider, Typography, CircularProgress, Chip } from '@mui/material'
import './styles.scss'
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import WebcamCapture from '../WebcamCapture/WebcamCapture';
import PhotoGalleryWithCam from '../PhotoGalleryWithCam/PhotoGalleryWithCam';
import { ChildEntity, QuestionDto } from 'src/dtos/AwTypes';
import { FormikProps } from 'formik';
import { Failure, InspectionChecklistQuestionResponseDto, InspectionChecklistSectionResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import { Status } from 'src/dtos/Statuses';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PhotoCameraBackOutlinedIcon from '@mui/icons-material/PhotoCameraBackOutlined';
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';

type Props = {
    formik: FormikProps<InspectionChecklistSectionResponseDto>;
    questionDetails: QuestionDto;
    questionIndex: number;
    taggedOutComponents?: ChildEntity[]; 
    setTaggedOutComponents?: React.Dispatch<React.SetStateAction<ChildEntity[]>>
    demoMode: Boolean;
    updateSection: () => void;
}

function YesOrNoQuestion({formik, questionIndex, questionDetails, taggedOutComponents, setTaggedOutComponents, updateSection, demoMode}: Props) {
    const formikAnswer = formik.values.questionResponses[questionIndex];
    const formikAnswerString = `questionResponses[${questionIndex}]`;
    const [commentOpen, setCommentOpen] = useState(formikAnswer.comment ?? false);
    const [cameraOpen, setCameraOpen] = useState(false);
    const [photoGalleryOpen, setPhotoGalleryOpen] = useState(false);

    const [initialNo, setInitialNo] = useState(Boolean(formikAnswer.answer === "No"));
    const [initialYes, setInitialYes] = useState(Boolean(formikAnswer.answer === "Yes"));
    const [questionStatus, setQuestionStatus] = useState<Status>(formikAnswer.status);
    const [comment, setComment] = useState<string>(formikAnswer.comment ?? "");
    const [saveStatus, setSaveStatus] = useState<"Saved" | "Failed" | "Loading">()
    const photoGalleryImages: string[] = formikAnswer.images ?? [];

    const [taggedOutImg, setTaggedOutImg] = useState("");
 
    const setAnswer = (answer: "Yes" | "No" | null) => {
        if(answer === "Yes"){
            setInitialYes(true);
            setInitialNo(false);
            setQuestionStatus(Status.Passed)
        } else if (answer === "No"){
            setInitialNo(true);
            setInitialYes(false);
            setQuestionStatus(Status.Unanswered)
        } else{
            setInitialNo(false);
            setInitialYes(false);
            setQuestionStatus(Status.Unanswered)
        }
    }
    
    const setPhotoGalleryImages = (images: string[]) => {
        formik.setFieldValue(`${formikAnswerString}.images`, images);
    }

        
    const [firstMount, setFirstMount] = useState(true)
    useEffect(() => {
        if(firstMount){
            setFirstMount(false)
            return
        }

        const saveQuestion = async () => {
            try {
                setSaveStatus("Loading")

                updateSection()

                console.log(formikAnswer)
                //set time on save
                if((formikAnswer.timeAnswered === undefined || formikAnswer.timeAnswered === null) && (formikAnswer.status === Status.Failed || formikAnswer.status === Status.Passed)){
                    formikAnswer.timeAnswered = new Date()
                } else {
                    //if the question is unanswered clear time answered
                    formikAnswer.timeAnswered = undefined;
                }

                if(!demoMode){
                    const response = await InspectionChecklistResponseService.UpdateQuestion(formikAnswer.id, formikAnswer)
                    if(response.status >= 200 && response.status <= 299){
                        setSaveStatus("Saved")
                    }
                } else {
                    setSaveStatus(undefined)
                }
                
            } catch (error: any) {
                setSaveStatus("Failed")
            }
        }

        saveQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikAnswer])

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
    //Update FormikAnswer
    useEffect(() => {
        (async () => {
            async function updateAnswer() {
                await sleep(100)
                const answer = !initialNo && !initialYes ? null : initialYes ? "Yes" : "No";
                if(formikAnswer.answer === answer && formikAnswer.status === questionStatus){
                    //no change
                    return
                }
                formik.setFieldValue(`${formikAnswerString}.answer`, answer);
                formik.setFieldValue(`${formikAnswerString}.status`, questionStatus);
                if(questionStatus === Status.Unanswered){
                    //if the question changes to unanswered then untag the component and clear other answers
                    untagComponent();

                }
                
            };
            await updateAnswer()
        })();       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialYes, initialNo])


    const handleInitialYesClick = () => {
        initialYes ? setAnswer(null) : setAnswer("Yes");        
    }

    const handleInitialNoClick = () => {
        initialNo ? setAnswer(null) : setAnswer("No");
    }

    const handlePhotoClick = () => {
        if(photoGalleryImages.length === 0){
            setCameraOpen(true);
        } else {
            setPhotoGalleryOpen(true);
        }
    }

    const handleCommentClick = () => {
        setCommentOpen((prevState) => !prevState);
    }

    const handleRejectImage = (image: string) => {
        handlePhotoClick();
    }

    const handleApproveImage = (image: string, setCapturedImage: React.Dispatch<React.SetStateAction<string | null>>) => {
        setPhotoGalleryImages([...photoGalleryImages, image])
        setCameraOpen(false);
        setPhotoGalleryOpen(true);
        setCapturedImage(null);
    };

    //returns true if images remain
    const deleteImage = (image: string) => {
        const newImages = photoGalleryImages.filter((img: string) => img !== image);
        setPhotoGalleryImages(newImages);
        return Boolean(newImages.length > 0);
    }

    const untagComponent = () => {
        const component = questionDetails.relatedComponent;
        if(component === null || component === undefined){
            return
        }
        if(taggedOutComponents && setTaggedOutComponents){
            const index = taggedOutComponents.findIndex(tc => tc.id === component.id);
            const newComponents = [...taggedOutComponents]
            newComponents.splice(index, 1)
            setTaggedOutComponents(newComponents);
            
        }
    }

    function IssueResolvedQuestion({componentLabel}: {componentLabel: string}){
        const [failureCause, setFailureCause] = useState<string>(formikAnswer.failureCause ?? "");
        const [remidialAction, setRemidialAction] = useState<string>(formikAnswer.remidialAction ?? "");
        const [actionRequired, setActionRequired] = useState<string>(formikAnswer.actionRequired ?? "")
        
        const setIssueResolvedAnswer = (answer: boolean | null) => {
            formik.setFieldValue(`${formikAnswerString}.issueResolved`, answer)
            setAndValidateFailureCause();
        }
        
        const questionErrorObj = formik.errors.questionResponses?.[questionIndex] as InspectionChecklistQuestionResponseDto | undefined;

        const failureCauseError = questionErrorObj?.failureCause;
        const failureCauseHasValidAnswer = failureCause.length >= 10;
        
        const remidialActionError = questionErrorObj?.remidialAction;
        const remidialActionHasValidAnswer = remidialAction.length >= 10;

        const actionRequiredError = questionErrorObj?.actionRequired;
        const actionRequiredHasValidAnswer = actionRequired.length >= 10;

        const setAndValidateFailureCause = () => {
            formik.setFieldValue(`${formikAnswerString}.failureCause`, failureCause)
            //wait until field is set before validating
            setTimeout(() => {
                formik.validateField(`${formikAnswerString}.failureCause`)
                formik.setFieldTouched(`${formikAnswerString}.failureCause`)
            }, 200)
        }

        const handleResolvedYesClick = () => {
            if(failureCauseHasValidAnswer){
                formikAnswer.issueResolved === true ? setIssueResolvedAnswer(null) : setIssueResolvedAnswer(true);
                //if it equals true that means that this click is actually unanswering the question
            } else {
                formik.setFieldTouched(`${formikAnswerString}.failureCause`)
                //Wait until field is touched before setting error
                setTimeout(() => {
                    formik.setFieldError(`${formikAnswerString}.failureCause`, "Failure cause is required and must be at least 10 characters")
                }, 100)
            }
        }

        const handleResolvedNoClick = () => {
            
            if(failureCauseHasValidAnswer){ 
                formikAnswer.issueResolved === false ? setIssueResolvedAnswer(null) : setIssueResolvedAnswer(false);

            } else {
                formik.setFieldTouched(`${formikAnswerString}.failureCause`)
                //Wait until field is touched before setting error
                setTimeout(() => {
                    formik.setFieldError(`${formikAnswerString}.failureCause`, "Failure cause is required and must be at least 10 characters")
                }, 100)
            }   
        }
         
        const failureCauseHandleBlur = () => {
            setAndValidateFailureCause();
            if(failureCauseError){
                setIssueResolvedAnswer(null)
            }
        }

        const remidialActionHandleBlur = () => {
            formik.setFieldValue(`${formikAnswerString}.remidialAction`, remidialAction)
            if(remidialActionHasValidAnswer){
                setQuestionStatus(Status.Passed)
                formik.setFieldValue(`${formikAnswerString}.status`, Status.Passed)

                setTimeout(() => {
                    formik.validateField(`${formikAnswerString}.remidialAction`);
                }, 100)
                
            } else {
                setQuestionStatus(Status.Unanswered);
                formik.setFieldValue(`${formikAnswerString}.status`, Status.Unanswered)

                setTimeout(() => {
                    formik.setFieldError(`${formikAnswerString}.remidialAction`, "Remidial action is required and must be at least 10 characters")
                }, 100)
            }
            
        }   
        
        const actionRequiredHandleBlur = () => {
            formik.setFieldValue(`${formikAnswerString}.actionRequired`, actionRequired)
            if(actionRequiredHasValidAnswer){
                setQuestionStatus(Status.Failed)
                formik.setFieldValue(`${formikAnswerString}.status`, Status.Failed)

                setTimeout(() => {
                    formik.validateField(`${formikAnswerString}.actionRequired`);
                }, 100)
                
            } else {
                setQuestionStatus(Status.Unanswered);
                formik.setFieldValue(`${formikAnswerString}.status`, Status.Unanswered)

                setTimeout(() => {
                    formik.setFieldError(`${formikAnswerString}.actionRequired`, "Action required is required and must be at least 10 characters")
                }, 100)
            }
            
        }  

        const yesVariant = formikAnswer.issueResolved === true ? "contained" : "outlined";
        const noVariant = formikAnswer.issueResolved === false ? "contained" : "outlined";
        return (
            <>
            <QuestionTitle
                title={`What caused ${componentLabel} to fail?`}
                required
            />
            <TextField
                id={`${formikAnswerString}.failureCause`}
                name={`${formikAnswerString}.failureCause`}
                size="small"
                sx={{width: "100%"}}
                multiline
                maxRows={3}
                value={failureCause}
                onChange={(e) => {setFailureCause(e.target.value)}}
                onBlur={failureCauseHandleBlur}
                error={ formik.touched.questionResponses && Boolean(failureCauseError)}
                helperText={formik.touched.questionResponses && failureCauseError}
            />
            <QuestionTitle
                title="Has this issue now been resolved?"
                required
            />

            <Stack direction="row">
                <Button variant={yesVariant} color='success' onMouseDown={() => handleResolvedYesClick()}>Yes</Button>
                <Button variant={noVariant} color='error' onMouseDown={() => handleResolvedNoClick()}>No</Button>
            </Stack>

            {formikAnswer.issueResolved === true ? (
                <>
                    <QuestionTitle
                        title="What remidial action was taken to resolve this issue?"
                        required
                    />
                    <TextField
                        sx={{width: "100%"}}
                        multiline
                        maxRows={3}
                        size="small"
                        value={remidialAction}
                        onChange={(e) => {setRemidialAction(e.target.value)}}
                        onBlur={remidialActionHandleBlur}
                        error={formik.touched.questionResponses && Boolean(remidialActionError)}
                        helperText={Boolean(remidialActionError) && remidialActionError}
                    />
                </>     
            ) : formikAnswer.issueResolved === false && (
                <>
                    <QuestionTitle
                        title="What action is required to resolve this issue?"
                        required
                    />
                    <TextField
                        sx={{width: "100%"}}
                        multiline
                        maxRows={3}
                        size="small"
                        value={actionRequired}
                        onChange={(e) => {setActionRequired(e.target.value)}}
                        onBlur={actionRequiredHandleBlur}
                        error={formik.touched.questionResponses && Boolean(actionRequiredError)}
                        helperText={Boolean(actionRequiredError) && actionRequiredError}

                    />
                </>
            )}
            
            </>
            
        )
    }

    function GroupedQuestion(){
        
        const handleFailedItemsChange = (value: ChildEntity[]) => {
            formik.setFieldValue(`${formikAnswerString}.failedItems`,value);

            if(formikAnswer.groupedQuestions && value.length > formikAnswer.groupedQuestions.length){
                //Value added
                const newGroupedQuestions = formikAnswer.groupedQuestions ? [...formikAnswer.groupedQuestions] : [];
                newGroupedQuestions.push({component: value[value.length-1], failureCause: ""})
                formik.setFieldValue(`${formikAnswerString}.groupedQuestions`, newGroupedQuestions)
                //TODO: Temporary fix to allow progression when groupedQuestion used.
                setQuestionStatus(Status.Passed)
                formik.setFieldValue(`${formikAnswerString}.status`, Status.Passed)

                
            } else {
                //Value/s removed
                const originalValue = formikAnswer.failedItems ?? [];
                const itemsRemoved = originalValue.filter(v => !value.includes(v))
                if(formikAnswer.groupedQuestions){
                    let newGroupedQuestions = [...formikAnswer.groupedQuestions];
                    formikAnswer.groupedQuestions.forEach((groupedQuestion: Failure) => {
                        if(groupedQuestion.component && itemsRemoved.includes(groupedQuestion.component)){
                            newGroupedQuestions = newGroupedQuestions.filter(e => e !== groupedQuestion)   
                        }
                    })
                    formik.setFieldValue(`${formikAnswerString}.groupedQuestions`, newGroupedQuestions)

                }
            }

        }

        return (
            <div style={{paddingTop: "0.001rem"}}>
                <QuestionTitle
                    title={`Which ${questionDetails.relatedComponent?.label} failed?`}
                    required
                />
                <Autocomplete
                    multiple
                    id="failedItems"
                    options={questionDetails.relatedComponent?.children ?? []}
                    onChange={(_, value) => handleFailedItemsChange(value)}
                    value={formikAnswer.failedItems}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='outlined'
                            size="small"
                            label={`Failed ${questionDetails.relatedComponent?.label}`}
                        />
                    )}
                />
                {formikAnswer.failedItems && formikAnswer.failedItems.map((childComponent, index) => (
                    <Stack key={childComponent.id}>
                        <GroupedIssueResolvedQuestion 
                            componentLabel={childComponent.label}
                            groupedQuestion={formikAnswer.groupedQuestions ? formikAnswer.groupedQuestions[index] : {component: childComponent}}
                            groupedQuestionIndex={index}
                        />
                        {index < (formikAnswer.failedItems?.length ? formikAnswer.failedItems.length-1: 1) &&
                            <Divider variant="middle" sx={{padding:"0.5rem"}}/>
                        }
                    </Stack>
                    ))
                }
                
            </div>
        )
    }

    function GroupedIssueResolvedQuestion({componentLabel, groupedQuestion, groupedQuestionIndex} : {componentLabel: string, groupedQuestion: Failure, groupedQuestionIndex: number}){
        const [failureCause, setFailureCause] = useState<string>(groupedQuestion.failureCause ?? "");
        const [remidialAction, setRemidialAction] = useState<string>(groupedQuestion.remidialAction ?? "");
        const [actionRequired, setActionRequired] = useState<string>(groupedQuestion.actionRequired ?? "")

        const failureAnswerString = `${formikAnswerString}.groupedQuestions[${groupedQuestionIndex}]`
        const failureAnswer: Failure = formikAnswer.groupedQuestions ? formikAnswer.groupedQuestions[groupedQuestionIndex] : {}

        const setIssueResolvedAnswer = (answer: boolean | null) => {
            formik.setFieldValue(`${failureAnswerString}.issueResolved`, answer)
            setAndValidateFailureCause();
        }

        const questionErrorObj = formik.errors.questionResponses?.[questionIndex] as InspectionChecklistQuestionResponseDto | undefined;
        const groupedQuestionErrorObj = questionErrorObj?.groupedQuestions?.[groupedQuestionIndex] as Failure | undefined;

        const failureCauseError = groupedQuestionErrorObj?.failureCause;
        const failureCauseHasValidAnswer = failureCause.length >= 10;
        
        const remidialActionError = groupedQuestionErrorObj?.remidialAction;
        const remidialActionHasValidAnswer = remidialAction.length >= 10;

        const actionRequiredError = groupedQuestionErrorObj?.actionRequired;
        const actionRequiredHasValidAnswer = actionRequired.length >= 10;

        const setAndValidateFailureCause = () => {
            formik.setFieldValue(`${failureAnswerString}.failureCause`, failureCause)
            //wait until field is set before validating
            setTimeout(() => {
                formik.validateField(`${failureAnswerString}.failureCause`)
                formik.setFieldTouched(`${failureAnswerString}.failureCause`)
            }, 200)
        }

        const failureCauseHandleBlur = () => {
            setAndValidateFailureCause();
            if(failureCauseError){
                setIssueResolvedAnswer(null)
            }
        }

        const handleResolvedYesClick = () => {
            if(failureCauseHasValidAnswer){
                failureAnswer.issueResolved === true ? setIssueResolvedAnswer(null) : setIssueResolvedAnswer(true);
                //if it equals true that means that this click is actually unanswering the question
            } else {
                formik.setFieldTouched(`${failureAnswerString}.failureCause`)
                //Wait until field is touched before setting error
                setTimeout(() => {
                    formik.setFieldError(`${failureAnswerString}.failureCause`, "Failure cause is required and must be at least 10 characters")
                }, 100)
            }
        }

        const handleResolvedNoClick = () => {
            
            if(failureCauseHasValidAnswer){ 
                failureAnswer.issueResolved === false ? setIssueResolvedAnswer(null) : setIssueResolvedAnswer(false);

            } else {
                formik.setFieldTouched(`${failureAnswerString}.failureCause`)
                //Wait until field is touched before setting error
                setTimeout(() => {
                    formik.setFieldError(`${failureAnswerString}.failureCause`, "Failure cause is required and must be at least 10 characters")
                }, 100)
            }   
        }

        const remidialActionHandleBlur = () => {
            formik.setFieldValue(`${failureAnswerString}.remidialAction`, remidialAction)
            if(remidialActionHasValidAnswer){

                setTimeout(() => {
                    formik.validateField(`${failureAnswerString}.remidialAction`);
                }, 100)
                
            } else {

                setTimeout(() => {
                    formik.setFieldError(`${failureAnswerString}.remidialAction`, "Remidial action is required and must be at least 10 characters")
                }, 100)
            }
            
        }   
        
        const actionRequiredHandleBlur = () => {
            formik.setFieldValue(`${failureAnswerString}.actionRequired`, actionRequired)
            if(actionRequiredHasValidAnswer){

                setTimeout(() => {
                    formik.validateField(`${failureAnswerString}.actionRequired`);
                }, 100)
                
            } else {

                setTimeout(() => {
                    formik.setFieldError(`${failureAnswerString}.actionRequired`, "Action required is required and must be at least 10 characters")
                }, 100)
            }
            
        }
        const yesVariant = failureAnswer.issueResolved === true ? "contained" : "outlined";
        const noVariant = failureAnswer.issueResolved === false ? "contained" : "outlined";
        return (
            <>
            <QuestionTitle
                title={`What caused ${componentLabel} to fail?`}
                required
            />
            <TextField
                id={`${failureAnswerString}.failureCause`}
                name={`${failureAnswerString}.failureCause`}
                size="small"
                sx={{width: "100%"}}
                multiline
                maxRows={3}
                value={failureCause}
                onChange={(e) => {setFailureCause(e.target.value)}}
                onBlur={failureCauseHandleBlur}
                error={ formik.touched.questionResponses && Boolean(failureCauseError)}
                helperText={formik.touched.questionResponses && failureCauseError}
            />
            <QuestionTitle
                title="Has this issue now been resolved?"
                required
            />

            <Stack direction="row">
                <Button variant={yesVariant} color='success' onMouseDown={() => handleResolvedYesClick()}>Yes</Button>
                <Button variant={noVariant} color='error' onMouseDown={() => handleResolvedNoClick()}>No</Button>
            </Stack>

            {failureAnswer.issueResolved === true ? (
                <>
                    <QuestionTitle
                        title="What remidial action was taken to resolve this issue?"
                        required
                    />
                    <TextField
                        sx={{width: "100%"}}
                        multiline
                        maxRows={3}
                        size="small"
                        value={remidialAction}
                        onChange={(e) => {setRemidialAction(e.target.value)}}
                        onBlur={remidialActionHandleBlur}
                        error={formik.touched.questionResponses && Boolean(remidialActionError)}
                        helperText={Boolean(remidialActionError) && remidialActionError}
                    />
                </>     
            ) : failureAnswer.issueResolved === false && (
                <>
                    <QuestionTitle
                        title="What action is required to resolve this issue?"
                        required
                    />
                    <TextField
                        sx={{width: "100%"}}
                        multiline
                        maxRows={3}
                        size="small"
                        value={actionRequired}
                        onChange={(e) => {setActionRequired(e.target.value)}}
                        onBlur={actionRequiredHandleBlur}
                        error={formik.touched.questionResponses && Boolean(actionRequiredError)}
                        helperText={Boolean(actionRequiredError) && actionRequiredError}

                    />
                </>
            )}
            
            </>
            
        )
    }

    const initialYesVariant = initialYes ? "contained" : "outlined";
    const initialNoVariant = initialNo ? "contained" : "outlined";
    return (
        <>
            <Paper>
            <span style={{ display: 'flex' }}>
            
            <div className='question-body'>
                <Stack sx={{marginTop: "1rem"}}>
                    <Grid container>
                        <Grid item xs={10}>
                            {questionDetails.relatedComponent && (
                                <div style={{width: "fit-content", padding: "2px 10px", borderRadius: "12px",   backgroundColor: "#f2f2f2"}}>
                                    <Typography variant="body2" sx={{color: "#333"}}>{questionDetails.relatedComponent?.label}</Typography>
                                </div>
                            )}
                            {/* <Typography>{formikAnswer.timeAnswered?.toLocaleString()}</Typography> */}
                        </Grid>
                        <Grid item xs={2} sx={{display:"flex", justifyContent:"right"}}>
                            <>
                            {saveStatus === "Saved" ? (
                                <CloudDoneOutlinedIcon fontSize='small' color="success" sx={{marginTop:"0.2rem"}}/>
                            ): saveStatus === "Failed" ? (
                                <CloudOffOutlinedIcon fontSize='small' color="error" sx={{marginTop:"0.2rem"}}/>
                            ): saveStatus === "Loading" ? (
                                <CircularProgress size="1rem" color="info" sx={{marginTop:"0.2rem"}} />
                            ): (
                                <CloudQueueIcon fontSize='small' sx={{color: "#cfcfcf"}} />
                            )}  
                            </>

                        </Grid>

                    </Grid>
                    
                    <QuestionTitle
                        title={`${questionDetails.number}. ${questionDetails.label}`}
                        required={questionDetails.required}
                        infoText={questionDetails.additionalInformation}
                        marginTop='0.2rem'
                        fontSize={16}
                    />  
                        
                    
                </Stack>
                
                {questionStatus === Status.Unanswered && taggedOutComponents && taggedOutComponents.findIndex(ce => ce.id === questionDetails.relatedComponent?.id) !== -1 ? (
                    <Chip
                        label="Component tagged out."
                        color="warning"
                    />
                ) : (
                    <>
                        <Grid container>

                        <Grid item xs={8}>
                            <Stack direction="row">
                                <Button variant={initialYesVariant} color='success' onClick={handleInitialYesClick}>Yes</Button>
                                <Button variant={initialNoVariant} color='error' onClick={handleInitialNoClick}>No</Button>
                            </Stack>
                            
                        </Grid>
                        <Grid item xs={4} sx={{display: "flex", justifyContent:"right"}}>
                            <Stack direction="row">
                                <IconButton onClick={handlePhotoClick}>
                                    {photoGalleryImages.length > 0 ? (
                                        <CollectionsOutlinedIcon color='primary'/>
                                    ) : (
                                        <AddAPhotoOutlinedIcon/>
                                    )}
                                    
                                </IconButton>
                                <IconButton onClick={handleCommentClick}>
                                    <AddCommentOutlinedIcon/>
                                </IconButton>
                            </Stack>
                        </Grid>
                        </Grid>
                        {commentOpen && initialNo !== true && (
                            <>
                                <QuestionTitle title='Provide any additional commentary'/>
                                <TextField
                                    id={`${formikAnswerString}.comment`}
                                    name={`${formikAnswerString}.comment`}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    onBlur={() => formik.setFieldValue(`${formikAnswerString}.comment`, comment)}
                                    sx={{width: "100%"}}
                                    multiline
                                    minRows={2}
                                    maxRows={3}
                                    size="small"
                                />
                            </>
                        )}
                        {initialNo === true && questionDetails.relatedComponent && questionDetails.relatedComponent.children && questionDetails.relatedComponent.children.length > 1 ? (
                            <GroupedQuestion/>
                        ) : initialNo === true && (
                            <div style={{paddingTop: "0.001rem"}}>
                                <IssueResolvedQuestion componentLabel='this question'/>
                            </div>
                        )}
                    
                    </>
                )}
                
                <PhotoGalleryWithCam
                    photosData={photoGalleryImages}
                    photoGalleryOpen={photoGalleryOpen}
                    setPhotoGalleryOpen={setPhotoGalleryOpen}
                    setCameraOpen={setCameraOpen}
                    deletePhoto={deleteImage}
                />

                <WebcamCapture 
                    onApprove={handleApproveImage} 
                    cameraOpen={cameraOpen} 
                    setCameraOpen={setCameraOpen}
                    onReject={handleRejectImage}
                /> 
            </div>
               
            </span>
            </Paper>
            {/* Individual tag out if question failed and question related component requires tagging */}
            { questionStatus === Status.Failed && questionDetails.relatedComponent?.requiresTagging === true && (
                <Paper style={{display:"flex", padding: "1rem"}}> 
                    <Stack spacing={2} sx={{width:"100%"}}>
                        <Stack direction="row" spacing={2} sx={{display:"flex", alignItems: "center"}}>
                            <WarningAmberIcon fontSize='large' color='error'/>
                            <Typography>{`${questionDetails.relatedComponent?.label} has failed its inspection and now must be tagged out.`}</Typography>
                        </Stack>
                        <Stack spacing={1}>
                            <Typography sx={{textAlign:"center"}}>{`Please take a photo of ${questionDetails.relatedComponent?.label} tagged out`}</Typography>
                            <span style={{display:"flex", justifyContent: "center"}}>
                                <IconButton onClick={() => {
                                    if(taggedOutImg){
                                        setTaggedOutImg("");
                                        untagComponent();
                                    } else {
                                        setTaggedOutImg("Tagged out");
                                        if(setTaggedOutComponents && taggedOutComponents && questionDetails.relatedComponent){
                                            setTaggedOutComponents([...taggedOutComponents, questionDetails.relatedComponent])
                                        } else {
                                            console.log("Unable to set tagged out component")
                                        }
                                    }
                                    
                                }}>
                                    {taggedOutImg ? (
                                        <PhotoCameraBackOutlinedIcon fontSize='inherit' color='primary'/>
                                    ) : (
                                        <AddAPhotoOutlinedIcon fontSize='inherit'/>
                                    )
                                    }
                                </IconButton>
                            </span>

                        </Stack>
                    </Stack>
                </Paper>
            )}
        </>
        
        
    )
}



//YesOrNoQuestion.defaultProps = defaultProps;

export default YesOrNoQuestion