import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Button, Card, CardActions, CardContent, Chip, Stack, TextField, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService'
import LearningDocumentResponseService from 'src/Services/Training/LearningDocuments/LearningDocumentResponseService'
import { guidRegex } from 'src/Utils/helperFunc'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { ChipColor } from 'src/config/constants'
import { CompetencyDocumentStatusString, ComptencyDocumentStatus, StartDocumentRequestDto } from 'src/dtos/Training/LearningDocumentResponse.dto'

function AssessmentTeamMembers() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const [searchTerm, setSearchTerm] = useState<string>("")
    const navigate = useNavigate()

    
    const trainableTeamMembersQuery = useQuery({
        queryKey: ["trainableTeamMembers"],
        queryFn: async () => {
            return await TeamMemberService.GetTrainableTeamMembers();
        }
    })

    const trainableCompetencyDocuments = useQuery({
        queryKey: ["trainableCompetencyDocuments", searchParams.get("teamMember")],
        queryFn: async () => {
            const tmID = searchParams.get("teamMember");
            if(tmID){
                return await LearningDocumentResponseService.GetTrainableCompetencyDocuments(tmID)
            } else{
                return []
            }
        },
        placeholderData: []
    })

    //handle query errors
    useEffect(() => {
        if(trainableTeamMembersQuery.isError){
            setMessageItem({error: trainableTeamMembersQuery.error})
        }
        if(trainableCompetencyDocuments.isError){
            setMessageItem({error: trainableCompetencyDocuments.error})
        }
    }, [trainableTeamMembersQuery.isError, trainableTeamMembersQuery.error, trainableCompetencyDocuments.isError, trainableCompetencyDocuments.error])

    const startAssessmentMutation = useMutation({
        mutationFn: ({publishedVersionId, teamMemberId, responseId}: {publishedVersionId: string, teamMemberId: string, responseId?: string}) => {
            const startRequest: StartDocumentRequestDto = {
                publishedVersionId: publishedVersionId,
                traineeIds: [teamMemberId],
                trainerIds: [],
                responseId: responseId
            }
            return LearningDocumentResponseService.StartDocument(startRequest)
        },
        onSuccess: (data) => {
            //Navigate to new 
            navigate({pathname: window.location.pathname + `/${data.data.id}`})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    const handleStartAssessment = (publishedVersionId: string) => {
        const tmId = searchParams.get("teamMember")
        if(tmId && guidRegex.test(tmId)){
            startAssessmentMutation.mutate({publishedVersionId: publishedVersionId, teamMemberId: tmId} )
        } else {
            setMessageItem({errorMessage: "Invalid Team Member Id"});
        }
    }

    const handleRetryNYCStart = (publishedVersionId: string, responseId?: string) => {
        console.log(responseId)
        const tmId = searchParams.get("teamMember")
        if(tmId && guidRegex.test(tmId)){
            startAssessmentMutation.mutate({publishedVersionId: publishedVersionId, teamMemberId: tmId, responseId: responseId} )
        } else {
            setMessageItem({errorMessage: "Invalid Team Member Id"});
        }
    }

    const handleResumeAssessment = (responseId?: string) => {
        if(responseId){
            navigate({pathname: window.location.pathname + `/${responseId}`})
        }
    }

    const ChipColour = (status: ComptencyDocumentStatus): ChipColor => {
        switch(status) {
            case ComptencyDocumentStatus.InProgress:
                return "primary"
            case ComptencyDocumentStatus.NotYetCompetent:
                return "warning"
            case ComptencyDocumentStatus.Rescinded:
                return "warning"
            case ComptencyDocumentStatus.completed:
                return "success"
            default:
                return "default"

        }
    }
    

    return (
        <div style={{display:"flex", justifyContent:"center"}}>
            <Stack spacing={3} sx={{alignItems: "center", maxWidth:"80%", marginTop:"2rem"}}>
                <Stack spacing={1} sx={{alignItems: "center", width: "30rem"}}>
                    <Typography variant='h6'>Who are you Assessing?</Typography>
                    <Autocomplete
                        id="trainableTeamMember"
                        options={trainableTeamMembersQuery.data ?? []}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={trainableTeamMembersQuery.data?.find(option => option.id === searchParams.get("teamMember")) || null}
                        onChange={(_, value) => {
                            if(value){
                                searchParams.set("teamMember", value.id)
                            } else {
                                searchParams.delete("teamMember")
                            }
                            setSearchParams(searchParams)
                            trainableCompetencyDocuments.refetch();
                        }}  
                        sx={{width:"25rem"}}
                        renderInput={(params) => 
                            <TextField {...params}/>
                        }
                    />

                </Stack>
                {trainableCompetencyDocuments.isLoading ? (
                    <>Loading...</>
                ) : trainableCompetencyDocuments.data && trainableCompetencyDocuments.data.length > 0 && (
                    <>
                        <TextField
                            label="Search"
                            size='small'
                            sx={{width:"25rem"}}
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value)
                            }}                        
                        />
                        <Box sx={{display:"flex", flexWrap:"wrap", gap:"1rem", justifyContent:"center"}}>
                            {trainableCompetencyDocuments.data && trainableCompetencyDocuments.data
                                .filter(cd => cd.label.toLowerCase().includes(searchTerm.toLowerCase()))
                                .map((competencyDocument, index) => (
                                <Card sx={{width: "20rem"}} key={index}>
                                    <CardContent>
                                        <Typography>{competencyDocument.label}</Typography>
                                        <Chip 
                                            sx={{marginTop:"0.5rem"}}
                                            label={CompetencyDocumentStatusString[competencyDocument.status]}
                                            color={ChipColour(competencyDocument.status)}
                                        />
                                    </CardContent>
                                    <CardActions sx={{display:"flex", justifyContent:"center"}}>
                                        {competencyDocument.status === ComptencyDocumentStatus.InProgress && (
                                            <Button variant='contained' onClick={() => handleResumeAssessment(competencyDocument.responseId)}>
                                                Resume Assessment
                                            </Button>
                                        )}
                                        {(competencyDocument.status === ComptencyDocumentStatus.Unattempted || competencyDocument.status === ComptencyDocumentStatus.Rescinded) && (
                                            <LoadingButton 
                                                variant='contained'
                                                onClick={() => handleStartAssessment(competencyDocument.publishedVersionId)}
                                                loading={startAssessmentMutation.isPending}
                                            >
                                                Start Assessment 
                                            </LoadingButton>
                                        )}
                                        {competencyDocument.status === ComptencyDocumentStatus.NotYetCompetent && (
                                            <Stack direction={"column"} spacing={2}>
                                                <LoadingButton 
                                                    variant='contained'
                                                    onClick={() => handleStartAssessment(competencyDocument.publishedVersionId)}
                                                    loading={startAssessmentMutation.isPending}
                                                >
                                                    Retry Entire Assessment 
                                                </LoadingButton>
                                                <LoadingButton
                                                    variant='contained'
                                                    onClick={() => handleRetryNYCStart(competencyDocument.publishedVersionId, competencyDocument.responseId)}
                                                    loading={startAssessmentMutation.isPending}
                                                >
                                                    Retry Not Yet Competent Topics
                                                </LoadingButton>
                                            </Stack>
                                        )}    
                                    </CardActions>
                                </Card>
                            ))}
                        </Box>
                    </>
                )}
            </Stack>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </div>
    )
}

export default AssessmentTeamMembers