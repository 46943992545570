import { Box } from "@mui/material";
import React from "react";
import UserAppBar from "./UserAppbar";

const UserLayout = () => {
    return (
        <main className="App">
            <Box sx={{display: "flex"}}>
                <UserAppBar/>
            </Box>
        </main>
    )
}

export default UserLayout;