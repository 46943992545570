import axios, { AxiosResponse } from "axios";
import { GetBearer } from "src/Utils/authUtils";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import { SessionAttendee, TrainingSession } from "src/dtos/Training/TrainingSession.dto";
import { ListParameters, SortDirection } from "../ListParameters";
import { NameDto } from "src/dtos/NameDto.dto";
import { AttendanceSheetAttendee } from "src/dtos/Training/AttendanceSheetResponse.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/TrainingSession";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class TrainingSessionService {
    static GetDefaultValues() {
        const defaultVals: TrainingSession = {
            id: "00000000-0000-0000-0000-000000000000",
            label:"",
            date: null,
            sessionTrainingDocuments: [],
            sessionAttendanceSheets: [],
            sessionOnlineLearnings: [],
            trainingGroups: [],
            sessionAttendees: [],
            sessionResponses: [],
            sessionLockDate: null,
            sessionLockOverride: false,
            enabled: true,
        }   
        return defaultVals;
    }

    static async Get(id: string): Promise<TrainingSession> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<TrainingSession> = await axios.get<TrainingSession>(`${apiBaseUrl}/Get/${id}`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetList(listParam?: ListParameters, searchTerm?: string, startDate?: Date, endDate?: Date, includeDisabled?: boolean): Promise<ListResponseDto> {
        try {
            const bearer = await GetBearer();

            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetList`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    startDate: startDate,
                    endDate: endDate,
                    includeDisabled: includeDisabled  
                }
            });
            return response.data;
        } catch(error: any) {
            if(error.response !== undefined) {
                if(error.response.status != null && error.response.status === 404) {
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error
        }
    }

    static async GetNames(): Promise<NameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetAssignableTrainees(id: string): Promise<NameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetAssignableTrainees/${id}`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetAssignableTrainers(id: string): Promise<NameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetAssignableTrainers/${id}`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetTodaysSessions(): Promise<TrainingSession[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<TrainingSession[]> = await axios.get<TrainingSession[]>(`${apiBaseUrl}/GetTodaysSessions`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(data: TrainingSession): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/Create`,data,{
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, data: TrainingSession): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Update/${id}`, data, {
                headers: {
                    Authorization: bearer
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async AddAttendee(trainingSessionId: string, newSessionAttendee: SessionAttendee): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/AddAttendee/${trainingSessionId}`, newSessionAttendee, {
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async RemoveAttendee(trainingSessionId: string, id: number): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.delete<AxiosResponse>(`${apiBaseUrl}/RemoveAttendee/${trainingSessionId}`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    id: id
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async StartSession(trainingSessionId: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/StartSession/${trainingSessionId}`, null, {
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateAttendanceSheetAttendee(id: number, updateAttendee: AttendanceSheetAttendee): Promise<AxiosResponse<AttendanceSheetAttendee>> {
        
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/UpdateAttendanceSheetAttendee/${id}`, updateAttendee, {
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

}