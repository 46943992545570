import React from 'react'
import { RouteType } from "./config";
import HomePage from '../pages/HomePage/HomePage';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SettingsIcon from '@mui/icons-material/Settings';
import Attractions from '../pages/Resources/Attractions/Attractions';
import ResourcesPageLayout from '../pages/Resources/ResourcesPageLayout';
import ResourcesIndex from '../pages/Resources/ResourcesIndex';
import Locations from '../pages/Resources/Locations/Locations';
import ChecklistPageLayout from '../pages/Checklists/ChecklistPageLayout';
import ChecklistIndex from '../pages/Checklists/ChecklistIndex';
import InspectionsChecklists from '../pages/Checklists/MaintenanceInspectionsChecklists/InspectionsChecklists';
import UserInspectionChecklists from 'src/pages/User Site/Inspections/InspectionChecklists/UserInspectionChecklists';
import AttractionForm from 'src/pages/Resources/Attractions/AttractionForm/AttractionForm';
import DemoQuestions from 'src/pages/User Site/DemoQuestions/DemoQuestions';
import InspectionsChecklistCreation from 'src/pages/Checklists/MaintenanceInspectionsChecklists/InspectionsChecklistCreation/InspectionsChecklistCreation';
import InspectionChecklistForm from 'src/pages/Checklists/MaintenanceInspectionsChecklists/InspectionChecklistForm/InspectionChecklistForm';
import Journal from 'src/pages/User Site/Inspections/Journal/Journal';
import Inspection from 'src/pages/User Site/Inspections/Inspection/Inspection';
import AttendanceSheetDemo from 'src/pages/User Site/TrainingDocuments/AttendanceSheetDemo';
import CompletedInspections from 'src/pages/User Site/Inspections/CompletedInspections/CompletedInspections';
import LearningDocuments from 'src/pages/Checklists/LearningContentChecklists/LearningDocuments/LearningDocuments';
import LearningDocumentForm from 'src/pages/Checklists/LearningContentChecklists/LearningDocumentForm/LearningDocumentForm';
import LearningDocumentCreator from 'src/pages/Checklists/LearningContentChecklists/LearningContentCreator/LearningDocumentCreator';
import SchoolIcon from '@mui/icons-material/School';
import LearningItems from 'src/pages/Training/LearningItems/LearningItem/LearningItems';
import TrainingGroupCreator from 'src/pages/Training/TrainingGroups/TrainingGroupCreator/TrainingGroupCreator';
import TrainingGroups from 'src/pages/Training/TrainingGroups/TrainingGroups';
import TrainingSessions from 'src/pages/Training/TrainingSessions/TrainingSessions';
import TrainingSessionCreator from 'src/pages/Training/TrainingSessions/TrainingSessionCreator/TrainingSessionCreator';
import PeopleIcon from '@mui/icons-material/People';
import TeamMember from 'src/pages/HumanResources/TeamMembers/TeamMember/TeamMemberCreator';
import TeamMembers from 'src/pages/HumanResources/TeamMembers/TeamMembers';
import AssessmentTeamMembers from 'src/pages/User Site/Training/Assessments/AssessmentTeamMembers';
import LearningDocumentPage from 'src/pages/User Site/Training/LearningDocumentPage/LearningDocumentPage';
import TodaysTrainingSessions from 'src/pages/User Site/Training/TrainingSession/TodaysTrainingSessions';
import TrainingSessionPage from 'src/pages/User Site/Training/TrainingSession/TrainingSessionPage';
import AttendanceSheet from 'src/pages/User Site/Training/TrainingSession/Components/AttendanceSheet/AttendanceSheet';
import IndividualAttendanceSheet from 'src/pages/User Site/Training/TrainingSession/Components/AttendanceSheet/IndividualAttendanceSheet';
import BulkActions from 'src/pages/HumanResources/BulkActions/BulkActions';
import TrainingGroupsTrainer from 'src/pages/HumanResources/TrainingGroupsTrainer/TrainingGroupsTrainer';
import TrainingGroupsTrainee from 'src/pages/HumanResources/TrainingGroupsTrainee/TrainingGroupsTrainee';

export const adminRoutes: RouteType[] = [
    {
        index: true,
        element: <HomePage/>,
        state: "home",
        pageName: "Home"
    },
    // {
    //     path: "/admin/dashboard",
    //     element: <DashboardPageLayout/>,
    //     state: "dashboard",
    //     sidebarProps: {
    //         displayText: "Dashboards",
    //         icon: <DashboardCustomizeOutlinedIcon/>
    //     },
    //     child: [
    //         {
    //             index: true,
    //             element: <DashboardIndex/>,
    //             state: "dashboard.index",
    //             pageName: "Dashboards"
    //         },
    //         {
    //             path: "/admin/dashboard/maintenanceInspections",
    //             element: <MaintenanceDashboard/>,
    //             state: "dashboard.maintenanceInspections",
    //             pageName: "Maintenance Inspections Dashboard",
    //             sidebarProps: {
    //                 displayText: "Maintenance Inspections"
    //             }
    //         }
    //     ]
    // },
    {
        path: "/admin/HumanResources",
        state: "HumanResources",
        element: <ResourcesPageLayout/>,
        sidebarProps: {
            displayText: "Human Resources",
            icon: <PeopleIcon/>
        },
        child: [
            {
                path: "/admin/HumanResources/TeamMembers",
                element: <TeamMembers/>,
                state: "HumanResources.TeamMembers",
                pageName: "Team Members",
                sidebarProps: {
                    displayText: "Team Members"
                }
            },
            {
                path: "/admin/HumanResources/TeamMembers/:id",
                element: <TeamMember/>,
                state: "HumanResources.TeamMembers",
                pageName: "Team Member"
            },
            {
                path: "/admin/HumanResources/Actions",
                element: <BulkActions/>,
                state: "HumanResources.Actions",
                pageName: "Actions",
                sidebarProps: {
                    displayText: "Actions"
                }
            },
            {
                path: "/admin/HumanResources/TrainingGroupsTrainer",
                element: <TrainingGroupsTrainer/>,
                state: "HumanResources.TrainingGroupsTrainer",
                pageName: "Training Groups [Trainer]",
                guideLink: "https://adventureworldthemepark.sharepoint.com/:f:/s/AW-Software/EgKyEczskwlNtCquq_XhhloBtOWMgf3b6WLTaLbdJOcKXA?e=YRXAtd",
                sidebarProps: {
                    displayText: "Training Groups [Trainer]"
                }
            },
            {
                path: "/admin/HumanResources/TrainingGroupsTrainee",
                element: <TrainingGroupsTrainee/>,
                state: "HumanResources.TrainingGroupsTrainee",
                pageName: "Training Groups [Trainee]",
                guideLink: "https://adventureworldthemepark.sharepoint.com/:f:/s/AW-Software/EgKyEczskwlNtCquq_XhhloBtOWMgf3b6WLTaLbdJOcKXA?e=YRXAtd",
                sidebarProps: {
                    displayText: "Training Groups [Trainee]"
                }
            }
        ]
    },
    {
        path: "/admin/resources",
        element: <ResourcesPageLayout/>,
        state: "resources",
        sidebarProps: {
            displayText: "Resources",
            icon: <SettingsIcon/>
        },
        child: [
            {
                index: true,
                element: <ResourcesIndex/>,
                state: "resources.index",
            },
            {
                path: "/admin/resources/locations",
                element: <Locations/>,
                state: "resources.locations",
                pageName: "Locations",
                sidebarProps: {
                    displayText: "Locations"
                }
            }
        ]
    },
    {
        path: "/admin/attractionInspections",
        element: <ChecklistPageLayout/>,
        state: "attractionInspections",
        sidebarProps: {
            displayText: "Attraction Inspections",
            icon: <ChecklistIcon/>
        },
        child: [
            {
                index: true,
                element: <ChecklistIndex/>,
                state: "checklists.index"
            },
            {
                path: "/admin/attractionInspections/attractions",
                element: <Attractions/>,
                state: "attractionInspections.attractions",
                pageName: "Attractions",
                sidebarProps: {
                    displayText: "Attractions"
                }
            },
            {
                path: "/admin/attractionInspections/attractions/:id",
                element: <AttractionForm/>,
                state: "attractionInspections.attractions",
                pageName: "Attraction"
            },
            {
                path: "/admin/attractionInspections/inspections",
                element: <InspectionsChecklists/>,
                state: "attractionInspections.inspections",
                pageName: "Inspections",
                sidebarProps: {
                    displayText: "Inspections"
                }
            },
            {
                path: "/admin/attractionInspections/inspections/:id",
                element: <InspectionChecklistForm/>,
                state: "attractionInspections.inspection",
                pageName: "Attraction Inspection"
            },
            {
                path: "/admin/attractionInspections/inspections/:inspectionId/:versionId",
                element: <InspectionsChecklistCreation/>,
                state: "attractionInspections.inspections.creator",
                pageName: "Attraction Inspection"
            },
        ]
    }, 
    {
        path: "/admin/training",
        element: <ChecklistPageLayout/>,
        state: "training",
        sidebarProps: {
            displayText: "Training",
            icon: <SchoolIcon/>
        },
        child: [
            {
                path: "/admin/training/learningDocuments",
                element: <LearningDocuments/>,
                state: "training.learningDocuments",
                pageName: "Learning Documents",
                sidebarProps: {
                    displayText: "Learning Documents"
                }
            },
            {
                path: "/admin/training/learningDocuments/:id",
                element: <LearningDocumentForm/>,
                state: "training.learningDocuments",
                pageName: "Learning Document"
            },
            {
                path: "/admin/training/learningDocuments/:learningDocumentId/:versionId",
                element: <LearningDocumentCreator/>,
                state: "training.learningDocuments",
                pageName: "Learning Document"
            }, 
            {
                path: "/admin/training/learningItems",
                element: <LearningItems/>,
                state: "training.learningItems",
                pageName: "Learning Items",
                sidebarProps: {
                    displayText: "Learning Items"
                },
            },
            {
                path: "/admin/training/trainingGroups",
                element: <TrainingGroups/>,
                state: "training.trainingGroup",
                pageName: "Training Groups",
                sidebarProps: {
                    displayText: "Training Groups"
                },
            },
            {
                path: "/admin/training/trainingGroups/:id",
                element: <TrainingGroupCreator/>,
                state: "training.trainingGroup",
                pageName: "Training Group"
            },
            {
                path: "/admin/training/trainingSessions",
                element: <TrainingSessions/>,
                state:"training.trainingSessions",
                pageName: "Training Sessions",
                sidebarProps: {
                    displayText: "Training Sessions"
                },
            },
            {
                path: "/admin/training/trainingSessions/:id",
                element: <TrainingSessionCreator/>,
                state: "training.trainingSessions",
                pageName: "Training Session"
            }
        ]
    }
    
 
]

export const userRoutes: RouteType[] =[
    {
        index: true,
        element: <HomePage/>,
        state: "home",
        pageName: "Home"
    },  
    {
        path: "/user/attractionInspections",
        element: <ChecklistPageLayout/>,
        state: "user.attractionInspections",
        sidebarProps: {
            displayText: "Attraction Inspections",
            icon: <ChecklistIcon/>
        },
        child: [
            {
                path: "/user/attractionInspections/inspectionChecklists",
                element: <UserInspectionChecklists/>,
                state: "user.attractionInspections.inspectionChecklists",
                pageName: "Inspection Checklists",
                sidebarProps: {
                    displayText: "Inspection Checklists",
                }
            },
            {
                path: "/user/attractionInspections/inspectionChecklists/:responseId",
                element: <Inspection/>,
                state: "userChecklists.checklist",
                pageName: "Inspection Checklist"
            },
            {
                path: "/user/attractionInspections/inspections",
                element: <CompletedInspections/>,
                state: "user.inspections",
                pageName: "Completed Inspections",
                sidebarProps: {
                    displayText: "Completed Inspections",
                }
            },
            {
                path: "/user/attractionInspections/MaintenanceJournal",
                element: <Journal/>,
                state: "user.maintenanceJournal",
                pageName: "Maintenance Journal",
                sidebarProps: {
                    displayText: "Journal",
                },
            },
        ]
    },
    {
        path: "/user/InspectionDemo/:id",
        element: <DemoQuestions/>,
        state: "InspectionDemo",
        pageName: "Inspection Demonstration"
    },
    {
        path: "/user/training",
        element: <ChecklistPageLayout/>,
        state: "user.training",
        pageName: "Training",
        sidebarProps: {
            displayText: "Training",
            icon: <SchoolIcon/>
        },
        child: [
            {
                path: "/user/training/sessions",
                element: <TodaysTrainingSessions/>,
                state: "user.training.sessions",
                pageName: "Training Sessions",
                sidebarProps: {
                    displayText: "Today's Sessions",
                },
            },
            {
                path: "/user/training/sessions/:id",
                element: <TrainingSessionPage/>,
                state: "user.training.session",
                pageName: "Training Session",
            },
            {
                path: "/user/training/sessions/:id/:responseId",
                element: <LearningDocumentPage demoMode={false}/>,
                state: "user.training.Session",
                pageName: "Training Session",
            },
            {
                path: "/user/training/sessions/:id/:responseId/Attendance",
                element: <AttendanceSheet/>,
                state: "user.training.Session.Attendance",
                pageName: "Training Session Attendance"
            },
            {
                path: "/user/training/sessions/:id/Attendance/:responseId",
                element: <IndividualAttendanceSheet/>,
                state: "user.training.Session.Attendance",
                pageName: "Training Session Attendance"
            },
            {
                path: "/user/training/assessments",
                element: <AssessmentTeamMembers/>,
                state: "user.training.assessments",
                pageName: "Training Assessments",
                sidebarProps: {
                    displayText: "Assessments",
                },
            },
            {
                path: "/user/training/assessments/:responseId",
                element: <LearningDocumentPage demoMode={false}/>,
                state: "user.training.assessment",
                pageName: "Training Assessment",
            }
        ]
    },
    {
        path: "/user/DemotrainingDocuments/:versionId",
        element: <LearningDocumentPage demoMode={true}/>,
        state: "demo.trainingDocuments",
        pageName: "Training Document Demo"
    },
    {
        path: "/user/DemotrainingDocuments/:id/Attendance",
        element: <AttendanceSheetDemo/>,
        state: "demo.trainingDocuments.Attendance",
        pageName: "Attendance Sheet Demo"
    }
]

export default adminRoutes;