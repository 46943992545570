import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Paper, Tab, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import TrainingSessionService from 'src/Services/Training/TrainingSessionService';
import { guidRegex } from 'src/Utils/helperFunc';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import TrainingSessionDocuments from './Components/TrainingSessionDocuments';

function TrainingSessionPage() {    
    const [openTab, setOpenTab] = useState("Documents")
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const {id} = useParams();
    const trainingSessionQuery = useQuery({
        queryKey: ["trainingSession", id],
        queryFn: async () => {
            if(id !== undefined && guidRegex.test(id)){
                return await TrainingSessionService.Get(id);
            }
            return null
        }
    })

    //HandleQuery Errors
    useEffect(() => {
        if(trainingSessionQuery.isError){
            setMessageItem({error: trainingSessionQuery.error})
        }
    }, [trainingSessionQuery.error, trainingSessionQuery.isError])
    return (
        <div style={{padding:"1rem"}}>
            <Paper sx={{padding:"1rem", marginBottom:"1rem"}}>
                <Typography variant='h5'>{trainingSessionQuery.data?.label}</Typography>
            </Paper>
            <Paper>
                <TabContext value={openTab}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={(_, value) => setOpenTab(value)}>
                            <Tab label="Documents" value="Documents"/>
                            <Tab label="Team Members" value="TeamMembers"/>
                        </TabList>
                    </Box>
                    <TabPanel value="TeamMembers">
                        trainers and trainees
                    </TabPanel>
                    <TabPanel value="Documents">
                        <TrainingSessionDocuments trainingSessionQuery={trainingSessionQuery}/>
                    </TabPanel>
                </TabContext>
            </Paper>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </div>
    )
}

export default TrainingSessionPage