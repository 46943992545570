import { Stack, Skeleton, Box, Typography, IconButton } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import assets from 'src/assets';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import { LearningDocumentType } from 'src/dtos/Training/LearningDocument.dto';
import TrainingBottomNavigation from '../../TrainingDocuments/TrainingBottomNavigation/TrainingBottomNavigation';
import PreparationSection from '../../TrainingDocuments/TrainingDocumentComponents/PreparationSection/PreparationSection';
import Topic from '../../TrainingDocuments/TrainingDocumentComponents/Topic/Topic';
import LearningDocumentResponseService from 'src/Services/Training/LearningDocuments/LearningDocumentResponseService';
import LearningDocumentService from 'src/Services/Training/LearningDocuments/LearningDocumentService';
import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { guidRegex } from 'src/Utils/helperFunc';
import { useFormik } from 'formik';
import { GetCurrentUser } from 'src/Utils/authUtils';
import SignOff from './Components/SignOff';
import { LearningDocumentResponseDto } from 'src/dtos/Training/LearningDocumentResponse.dto';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


type Props = {
    demoMode: boolean;
}
function LearningDocumentPage({demoMode}: Props) {
    const {responseId} = useParams();//only used for non demoMode
    const {versionId} = useParams(); //only used for demoMode 
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const[currentUser, setCurrentUser] = useState<{id: string, name: string}>({id: "", name: ""})

    const navigate = useNavigate()
    
    useEffect(() => {
        setCurrentUser(GetCurrentUser())
    }, [responseId, versionId])
    
    
    //#region queries
    const documentResponseQuery = useQuery({
        queryKey: ["documentResponse", responseId],
        queryFn: async () => {
            if(demoMode && versionId && guidRegex.test(versionId)){
                return await LearningDocumentResponseService.GetInitialValues(versionId)
            }
            if(responseId && guidRegex.test(responseId)){
                return await LearningDocumentResponseService.Get(responseId)
            }
            return null
        }
    })
    
    const learningDocumentVersionQuery = useQuery({
        queryKey: ["learningDocumentVersion", documentResponseQuery.data?.learningDocumentVersionId],
        queryFn: async () => {
            if(documentResponseQuery.data){
                return await LearningDocumentVersionService.Get(documentResponseQuery.data.learningDocumentVersionId);
            }
            return null
        }
    })
    
    const learningDocumentQuery = useQuery({
        queryKey: ["learningDocument", learningDocumentVersionQuery.data?.learningDocumentId],
        queryFn: async () => {
            if(learningDocumentVersionQuery.data){
                return await LearningDocumentService.Get(learningDocumentVersionQuery.data.learningDocumentId)
            }
            return null
        } 
    })
    
    const updateResponseMutation = useMutation({
        mutationFn: ({id, values}: {id: string, values: LearningDocumentResponseDto}) => {
            return LearningDocumentResponseService.UpdateResponse(id, values)
        },
        retry: 5, 
        
    })
    //#endregion
    
    const handleBackClick = () => {
        navigate({ pathname: window.location.pathname.replace(new RegExp(`/${responseId}`), '') });
    }
    
    const formik = useFormik({
        enableReinitialize: true, 
        validateOnChange: false,
        initialValues: documentResponseQuery.data ?? LearningDocumentResponseService.GetDefaultValues(),
        onSubmit: () => {
            
        }
    })
    
    const hasMounted = useRef(false);
    useEffect(() => {
        if(hasMounted.current && responseId){
            //response has changed (not including topics etc)
            updateResponseMutation.mutate({id: responseId, values: formik.values})
        } else {
            hasMounted.current = true
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.attendanceSheetResponse, formik.values.competent])

    const initialLoading = documentResponseQuery.isLoading || learningDocumentVersionQuery.isLoading || learningDocumentQuery.isLoading;
    if(initialLoading){
        return (
            <>
                <Stack direction="row" sx={{width:"100%", padding:"1rem"}}>
                    {/* For logo */}
                    <Skeleton animation="wave" variant="circular" sx={{ width: "5rem", height: "5rem" }}/>
                    {/* For Title */}
                    <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                        <Skeleton animation="wave" sx={{width: "80%", display:"flex"}}/>
                    </div>
                </Stack>
                {/* For Body */}
                <div style={{width:"100%", padding:"1rem"}}>
                    <Skeleton animation="wave" variant="rectangular" height={500} />
                </div>  
            </>
        )
    }

    const learningDocumentVersion = learningDocumentVersionQuery.data ?? LearningDocumentVersionService.GetDefaultVersionValues();
    const learningDocument = learningDocumentQuery.data ?? LearningDocumentService.GetDefaultValues();

    return (
        <>
            <Box sx={{marginBottom:"4rem"}}>
                <Stack direction="row" sx={{width:"100%", padding:"1rem"}}>
                    <img alt="AW LOGO" src ={assets.images.logo} style={{ width: "5rem", height: "5rem" }}/>
                    <Typography variant='h5' sx={{width: "100%", paddingLeft:"1rem", paddingRight:"1rem", display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>{learningDocument.label}</Typography>    
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}} >
                        <IconButton onClick={handleBackClick}>
                            <ArrowBackIcon fontSize='large'/>
                        </IconButton>
                    </div>
                    
                </Stack>
                {learningDocumentVersion.initialSectionContent && (
                    <PreparationSection 
                        initialSectionContent={learningDocumentVersion.initialSectionContent} 
                        preparationChecklist={learningDocumentVersion.preparationChecklist}
                        preparationChecklistResponse={formik.values.preparationChecklistResponse}
                        formik={formik}
                        demoMode={demoMode}
                    />
                )}
                {learningDocumentVersion.topics.map((topic, index) => (
                    <Topic 
                        key={index}
                        index={index}
                        topic={topic}
                        topicResponse={formik.values.topicResponses[index]}
                        formikString={`topicResponses[${index}]`}
                        formik={formik}
                        documentType={learningDocument.type}
                        demoMode={demoMode}
                    />
                ))}
                <SignOff
                    formik={formik}
                    setMessageItem={setMessageItem}
                    learningDocument={learningDocument}
                    currentUser={currentUser}
                />

            </Box>
            {(learningDocument.type === LearningDocumentType.trainingDocumentNTM || learningDocument.type === LearningDocumentType.trainingDocumentRTM) && (
                <TrainingBottomNavigation/>
            )}
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default LearningDocumentPage