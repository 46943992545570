export enum Status{ 
    Passed,
    Failed, 
    Inprogress,
    Unanswered,
    NA,
    Published,
    Draft,
    Outdated,
    Outstanding,
    Resolved
}

export const StatusString : string[] = [
    "Passed",
    "Failed",
    "In Progress",
    "Unanswered",
    "N/A",
    "Published",
    "Draft",
    "Outdated",
    "Outstanding",
    "Resolved"
] 