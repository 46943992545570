import React from 'react';
import {Outlet} from "react-router-dom"
import {Box, Toolbar} from "@mui/material";
import sizeConfigs from "../../config/sizeConfigs"
import colorConfigs from "../../config/colorConfigs"
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import "./styles.scss"

const AdminLayout = () => {
    
    return (
        <main className="App">
            <Box  style={{display: "flex"}}>
                <div className="invisiblePrint">
                    <Topbar/>
                </div>
                <Box
                    className="invisiblePrint"
                    component="nav"
                    sx={{
                        width: sizeConfigs.sidebar.width,
                        flexShrink: 0
                    }}
                    >
                        <Sidebar/>
                </Box>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                        minHeight: "100vh",
                        backgroundColor: colorConfigs.mainBg
                    }}
                >
                    <Toolbar/>
                    <Outlet/>
                </Box>
            </Box>
        </main>
    )
}

export default AdminLayout;