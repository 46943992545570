import React, { useState } from 'react'
import Section from '../Section/Section'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import PhotoCameraFrontOutlinedIcon from '@mui/icons-material/PhotoCameraFrontOutlined';
import { Button, Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import './styles.scss'
import WebcamCapture from 'src/components/WebcamCapture/WebcamCapture';
import { Status } from 'src/dtos/Statuses';

type Props = {
    checklistName: string;
    onCapture: (image: string) => void;
    existingImage?: string;
    checklistStatus: Status;
    submitInspection?: () => void;
}

function FinalSignOffSection({checklistName, onCapture, existingImage, checklistStatus, submitInspection}: Props) {
    const [cameraOpen, setCameraOpen] = useState(false);
    const [capturedImage, setCapturedImage] = useState<string | null>(existingImage ?? null);

    const handleCameraOpen = () => {
        setCameraOpen(true)
    }

    const onPhotoApproved = (image: string) => {
        setCapturedImage(image);
        onCapture(image);
    }

    const onCancel = () => {
        setCapturedImage(null);
    }

    const handleSubmitClick = () => {
        if(submitInspection){
            submitInspection();
        }
    }

    return (
    <>
        <Section
            sectionTitle='Final Sign Off'
            checkPrevSectionPassed={() => true} //Can always be opened
        >
            <QuestionTitle
                title={`I certify that this checklist, ${checklistName}, has been answered to the best of my knowledge and abilities.`}
                required
            />
            <FormControlLabel control={<Checkbox />} label="I agree" />
            <QuestionTitle
                title="Please take a final sign off photo for identity confirmation"
                required
            />
            <span style={{display: "flex", justifyContent: "center"}}>
                <IconButton size='large' onClick={handleCameraOpen}>
                    {capturedImage ? (
                        <PhotoCameraFrontOutlinedIcon fontSize='inherit' color='primary'/>
                    ) : (
                        <AddAPhotoOutlinedIcon fontSize='inherit'/>
                    )}
                </IconButton>
            </span>
            {checklistStatus === Status.Passed ? (
                <>
                    <Typography>{`${checklistName} has passed.`}</Typography>
                </>
            ) : checklistStatus === Status.Inprogress ? (
                <>
                    <Typography>{`${checklistName} is incomplete and therefore can only be submitted as failed`}</Typography>
                </>
            ) : (
                <>
                    <Typography>{`${checklistName} has failed.`}</Typography>
                </>
            )}
            <Button onClick={handleSubmitClick} variant='contained' color={checklistStatus === Status.Passed ? "success" : "error"}>{`Submit as ${checklistStatus === Status.Passed ? "passed" : "failed"}`}</Button>
        </Section>
        <WebcamCapture
            onApprove={onPhotoApproved}
            onReject={onCancel}
            cameraOpen={cameraOpen}
            setCameraOpen={setCameraOpen}
            existingImage={existingImage}
        />
    </>
    
  )
}

export default FinalSignOffSection