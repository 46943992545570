import React, { useState } from "react";
import { AppBar, IconButton, ListItemIcon, Menu, MenuItem, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import sizeConfigs from "../../config/sizeConfigs";
import colorConfigs from "../../config/colorConfigs";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { AccountCircle, Logout } from "@mui/icons-material";
import { GetCurrentUserName, LogoutUser } from "src/Utils/authUtils";
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const Topbar = () => {
    const currentPageName = useSelector((state: RootState) => state.appState.currentPageName);
    const currentGuideLink = useSelector((state: RootState) => state.appState.guideLink);
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenForm = async () => {
        const formUrl = process.env.REACT_APP_FEEDBACK_FORM_URL;
        window.open(formUrl, '_blank')?.focus()
    }

    const handleLogout = async () => {
        await LogoutUser();
    }


    return (
        <AppBar
            position="fixed"
            sx={{
                width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                ml: sizeConfigs.sidebar.width,
                boxShadow: "unset",
                backgroundColor: colorConfigs.topbar.bg,
                color: colorConfigs.topbar.color
            }}
        >
            <Toolbar sx={{display:"-ms-inline-flexbox", justifyContent:"space-between"}} className="invisiblePrint">
                <Stack direction={"row"} spacing={1} sx={{alignItems:"center"}}>
                    <Typography variant ="h5">
                        {currentPageName}
                    </Typography>
                    {currentGuideLink && (
                        <Tooltip title="How to Guides">
                            <IconButton href={currentGuideLink} target="_blank">
                                <HelpCenterIcon fontSize="large"/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
                <div>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle fontSize="large"/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                        }}
                        keepMounted
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem>
                            <Typography>{GetCurrentUserName()}</Typography>
                        </MenuItem>
                        <MenuItem onClick={handleOpenForm}>
                            <ListItemIcon>
                                <FeedbackOutlinedIcon fontSize="small"/>
                            </ListItemIcon>
                            Feedback/Issue
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <Logout fontSize="small"/>
                            </ListItemIcon>
                            Sign Out
                        </MenuItem>
                    </Menu>
                    </div>
            </Toolbar>
        </AppBar>
    );  
};

export default Topbar;