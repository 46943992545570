import DOMPurify from "dompurify";

//Changes focus by increment, ignores elements with tabIndex of -1
export function FocusElement(increment: number) {
  // Get all focusable elements
  const focusableElements = Array.from(document.querySelectorAll('button, [href], input, select, textarea')).filter(element => {
    // Check if the element has a tabIndex attribute
    if (element.hasAttribute('tabIndex')) {
        const tabIndexValue = parseInt(element.getAttribute('tabIndex')?? "");
        // Exclude elements with tabIndex="-1"
        if (tabIndexValue === -1) return false;
    }

    // All other elements are considered focusable
    return true;
  });
  // Get the currently focused element
  var currentFocus = document.activeElement as HTMLElement;

  // Find the next element in the focusable array
  var currentIndex = focusableElements.indexOf(currentFocus);
  var nextIndex = (currentIndex + increment) % focusableElements.length;

  // Focus the next element
  const nextElement = focusableElements[nextIndex] as HTMLElement;
  if (nextElement) {
      nextElement.focus();
  }
}

export function EmailToNameString(email: string) {
  const [username] = email.split('@');
  const [firstName, lastName] = username.split('.');
  // Capitalize the first letter of each name
  const capitalizedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  const capitalizedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

  const fullName = `${capitalizedFirstName} ${capitalizedLastName}`
  return fullName;
}

export const sanitizeHtmlToIFrame = (input: string) => {
  const clean = DOMPurify.sanitize(input, {ALLOWED_TAGS:["iframe"], ADD_ATTR: ["allowfullscreen"]});
  return clean;
};

export const sanitizeHtml = (input: string) => {
  const clean = DOMPurify.sanitize(input)
  return clean
}

export const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/; //check if a string is a guid
