import React, { useEffect, useState } from 'react'
import { TableHeader } from '../../../components/Datatable/TableHeader'
import { ListParameters } from '../../../Services/ListParameters'
import Datatable from '../../../components/Datatable/Datatable'
import { AttractionNameDto, InspectionChecklist, ListResponseDto, LocationNameDto } from 'src/dtos/AwTypes'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import InspectionChecklistService from 'src/Services/Checklists/Inspections/InspectionChecklistService'
import { useNavigate } from 'react-router-dom'
import { Autocomplete, Box, Button, FormControlLabel, IconButton, Switch, TableCell, TextField, Typography } from '@mui/material'
import colorConfigs from 'src/config/colorConfigs'
import EditIcon from '@mui/icons-material/Edit'
import { DepartmentNameDto } from 'src/dtos/Resources/Department.dto'
import LocationService from 'src/Services/Resources/LocationService'
import DepartmentService from 'src/Services/Resources/DepartmentService'
import AttractionService from 'src/Services/Resources/AttractionService'
import ClickableTableRow from 'src/components/Datatable/ClickableTableRow'

type Props = {}

const InspectionsChecklists = (props: Props) => {
  const tableHeaders: TableHeader[] = [
    {label: '', field: "statusIndicator", width: "10px", sortable: false},
    {label: "Name", field: "label", width: "8rem", sortable: true},
    {label: "Description", field: "description", width: "10rem", sortable: false},
    {label: "Department", field: "department", width: "8rem", sortable: true},
    {label: "Attraction", field: "attraction", width: "8rem", sortable: true},
    {label: "Location", field: "location", width: "8rem", sortable: true},
    {label: '', field: "edit", width: "3rem", sortable: false}
  ]   
  
  type filterOptionsType = {
    department?: {id: string, label: string},
    attraction?: {id: string, label: string},
    location?: {id: string, label: string},
    includeDisabled?: boolean
  }

  const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined})
  const [filterOptions, setFilterOptions] = useState<filterOptionsType>({department: undefined, attraction: undefined, location: undefined, includeDisabled: false})
  const navigate = useNavigate();

  const initialListParams: ListParameters = {
    page: 1,
    pageSize: 30    
  }

  const fetchChecklistInspections = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
    const data = await InspectionChecklistService.GetList(listParams, searchTerm, filterOptions.department?.id, filterOptions.attraction?.id, filterOptions.location?.id, filterOptions.includeDisabled);
    return data;
  }

  const renderTableRow = ({data, index}: {data: InspectionChecklist, index: number}) => {
    return (
      <>
        <ClickableTableRow
          hover
          href={`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${data.id}`}
          key={`InspectionChecklistRow_${index}`}
          sx={{cursor: "pointer", textDecoration:"none"}}
        >
          <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor:data.enabled ? colorConfigs.datatable.active: colorConfigs.datatable.inActive}}></TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{data.label}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`}}>{data.description}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[3].width} !important`, maxWidth: `${tableHeaders[3].width} !important`}}>{data.department?.label}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[4].width} !important`, maxWidth: `${tableHeaders[4].width} !important`}}>{data.attraction?.label}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[5].width} !important`, maxWidth: `${tableHeaders[5].width} !important`}}>{data.location?.label}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[6].width} !important`, maxWidth: `${tableHeaders[6].width} !important`, paddingLeft: "0rem"}}><IconButton><EditIcon fontSize='small'/></IconButton></TableCell>
        </ClickableTableRow>
      </>
    )
  };

  const handleFormOpen = () => {
    navigate(`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}new`)
  }

  function FilterMenu(props: {handleClose: () => void}){
    const [includeDisabled, setIncludeDisabled] = useState(filterOptions.includeDisabled);
    const [department, setDepartment] = useState(filterOptions.department);
    const [departmentNames, setDepartmentNames] = useState<DepartmentNameDto[]>([]);
    const [attraction, setAttraction] = useState(filterOptions.attraction);
    const [attractionNames, setAttractionNames] = useState<AttractionNameDto[]>([]);
    const [location, setLocation] = useState(filterOptions.location);
    const [locationNames, setLocationNames] = useState<LocationNameDto[]>([]);
    

    useEffect(() => {
      const fetchLocationNames = async () => {
        try{
          const data = await LocationService.GetNames()
          setLocationNames(data)
        }
        catch (error: any) {
          setMessageItem({error: error});
        }
      }

      const fetchDepartmentNames = async () => {
        try {
          const data = await DepartmentService.GetNames()
          setDepartmentNames(data)
        } catch (error: any) {
          setMessageItem({error: error})
        }
      }

      const fetchAttractionNames = async () => {
        try {
          const data = await AttractionService.GetNames()
          setAttractionNames(data)
        } catch (error: any){
          setMessageItem({error: error})
        }
      }

      fetchLocationNames();
      fetchDepartmentNames();
      fetchAttractionNames();
    }, [])

    const handleIncludeDisabledChange = () => {
      setIncludeDisabled((prevVal) => !prevVal)
    }

    const handleOkClick = () => {
      props.handleClose()
      setFilterOptions({department: department, attraction: attraction, location: location, includeDisabled: includeDisabled})
    }

    return (
      <Box style={{padding: "1rem", display: "block"}}>
        <Typography variant='h6' sx={{paddingBottom: '1rem', paddingLeft: '0.5rem'}}>Filter</Typography>
        <Autocomplete
          disablePortal={false}
          id="department"
          options={departmentNames}
          size="small"
          sx={{ width: "15rem", paddingBottom: "0.5rem" }}
          value={department || null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.label ? option.label: ""}
          onChange={(e, value) => {
            if(value){
              setDepartment(value)
            } else {
              setDepartment(undefined)
            }
          }}
          renderInput={(params) => 
          <TextField {...params} label="Department"
          />}
        />
        <Autocomplete
          disablePortal={false}
          id="attraction"
          options={attractionNames}
          size="small"
          sx={{ width: "15rem", paddingBottom: "0.5rem" }}
          value={attraction || null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.label ? option.label: ""}
          onChange={(e, value) => {
            if(value){
              setAttraction(value)
            } else {
              setAttraction(undefined)
            }
          }}
          renderInput={(params) => 
          <TextField {...params} label="Attraction"
          />}
        />
        <Autocomplete
          disablePortal={false}
          id="location"
          options={locationNames}
          size="small"
          sx={{ width: "15rem", paddingBottom: "0.5rem" }}
          value={location || null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.label ? option.label: ""}
          onChange={(e, value) => {
            if(value){
              setLocation(value)
            } else {
              setLocation(undefined)
            }
          }}
          renderInput={(params) => 
          <TextField {...params} label="Location"
          />}
        />
        <FormControlLabel 
          control={
            <Switch 
              checked={includeDisabled}
              onChange={handleIncludeDisabledChange}  
            />
          }
          label={"Include Inactive?"}
          sx={{minWidth: '100%'}}
        />
        <Button onClick={handleOkClick}>Filter</Button>
      </Box>
    )

  }

  return (
    <>
      <Datatable
        tableHeaders={tableHeaders}
        initialListParams={initialListParams}
        tableTitle='Inspections'
        buttonLabel='Create New Inspection'
        buttonOnClick={handleFormOpen}
        showSearch={true}
        renderRow={renderTableRow}
        callService={fetchChecklistInspections}
        setMessageItem={setMessageItem}
        FilterMenu={FilterMenu}
      />
      <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>
          
  )
}

export default InspectionsChecklists