import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Avatar, Divider, Drawer, List, Stack, ListItemIcon } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { userRoutes } from 'src/routes/appRoutes';
import SidebarItemCollapse from './SidebarItemCollapse';
import SidebarItem from './SidebarItem';
import colorConfigs from 'src/config/colorConfigs';
import assets from 'src/assets';
import { AccountCircle, Logout } from "@mui/icons-material";
import { GetCurrentUserName, LogoutUser } from 'src/Utils/authUtils';


const drawerWidth = 300;
export default function UserAppBar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  const handleDrawerToggle = () => {
    setSideMenuOpen((prevState) => !prevState)
  }
  
  const drawer = (
    <Box className="invisiblePrint" sx={{ textAlign: 'center', backgroundColor: colorConfigs.userSideBar.bg, color: colorConfigs.userSideBar.color, height:"100%"}}>
        <Stack
            sx={{width: "100%", marginBottom: "0.5rem", marginTop: "1rem", paddingLeft: "1.75rem"}}
            direction="row"
            justifyContent="left"    
        >
          <IconButton size='small' href="/">
            <Avatar src ={assets.images.logo} sx={{ width: 50, height: 50 }}/>
          </IconButton>
            <Typography variant="h6" sx={{ display: "flex", alignItems: "center", paddingLeft: "1rem" }}>
                AW Hub
            </Typography>
            <Divider />
        </Stack>
      
      <List>
        {userRoutes.map((route, index) => (
            route.sidebarProps ? (
                    route.child ? (
                        <SidebarItemCollapse item={route} key={index}/>
                    ) : (
                <SidebarItem item={route} key={index}/>
                )
            ) : null
        ))}
      </List>
    </Box>
  );

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await LogoutUser();
}

  const currentPageName = useSelector((state: RootState) => state.appState.currentPageName);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar 
        position="static"
        className="invisiblePrint"
      >
        <Toolbar sx={{backgroundColor: colorConfigs.AW.blue}}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {currentPageName}
          </Typography>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>
                  <Typography>{GetCurrentUserName()}</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small"/>
                    Sign Out
                  </ListItemIcon>
                </MenuItem>
              </Menu>
            </div>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
            //container={container}
            variant='temporary'    
            open={sideMenuOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                display: { xs: 'block', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
        >
            {drawer}
        </Drawer>
      </nav>
      <Box component="main">
        <Outlet/>
      </Box>
    </Box>
  );
}