import React, { useEffect, useRef, useState } from 'react'
import { TableHeader } from './TableHeader'
import { Button, IconButton, Paper, Popover, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TextField, Tooltip, Typography } from '@mui/material';
import { ListParameters, SortDirection } from '../../Services/ListParameters';
import './styles.scss'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { ListResponseDto } from '../../dtos/AwTypes';
import { styled } from '@mui/material/styles';
import { MessageItem } from '../errorHandlingSnackbar';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

interface Props {
    tableHeaders: TableHeader[];
    callService: (listParams: ListParameters, searchTerm?: string) => Promise<ListResponseDto>;
    renderRow: ({data, index}: {data: any, index: number}) => ReactJSXElement;
    initialListParams: ListParameters;
    tableTitle?: string;
    buttonLabel?: string;
    buttonOnClick?: () => void;
    FilterMenu?: (props: {handleClose: () => void}) => ReactJSXElement;
    showSearch: boolean;
    setMessageItem: (caughtError: MessageItem) => void;
    toggleRefreshFlag?: boolean;
    height?: string;
}

function Datatable(props: Props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const {tableHeaders, FilterMenu} = props;
    const [listParams, setListParams] = useState<ListParameters>(props.initialListParams);
    const [firstPageData, setFirstPageData] = useState<ListResponseDto>({totalRecords: 0, data: []});
    const [searchTerm, setSearchTerm] = useState(searchParams.get("searchTerm") ?? "");    

    const {data, isError, error, isLoading, isFetching, refetch} = useQuery({
        queryKey: ["datatable", props.tableTitle, 1, searchTerm],
        queryFn: async () => {
            const response = await props.callService(listParams, searchTerm);
            return response;
        },
        placeholderData: {totalRecords: 0, data: []}
    })

    useEffect(() => {
        refetch()
    }, [props.toggleRefreshFlag, refetch])

    useEffect(() => {
        if(data){
            setFirstPageData(data)
        }
    }, [data])

    useEffect(() => {
        if(isError){
            props.setMessageItem({error: error})
        }

    }, [error, isError, props])

    const updateSearchTerm = (newSearch: string) => {
        setSearchTerm(newSearch)
        searchParams.set("searchTerm", newSearch)
        setSearchParams(searchParams)
        console.log(searchParams)
    }

    function TableToolbar() {
        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
        const [filterOpen, setFilterOpen] = useState(false);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            setFilterOpen(true);
          };
        
        const handleClose = () => {
            setAnchorEl(null);
            setFilterOpen(false);

            //wait for filter values to be set before refetchig
            setTimeout(() => {
                refetch();
            }, 1);
        };  
        const [searchInput, setSearchInput] = useState(searchTerm)
        return (
        <div>
            <div className='table-header'>
                <span>{props.tableTitle}</span>
                {props.buttonLabel && <Button variant='contained' color='primary' onClick={() => {props.buttonOnClick &&props.buttonOnClick();}}>{props.buttonLabel}</Button>}
            </div>
            <div className='table-header'>
                {props.showSearch && (
                    <Stack direction={"row"} spacing={1}>
                        <TextField
                            key={"searchTermInput"}
                            onBlur={() => {updateSearchTerm(searchInput);}}
                            onKeyDown={e => {
                                if(e.key === "Enter"){
                                    updateSearchTerm(searchInput)
                                }
                            }}
                            id="searchTermInput" 
                            name="searchTermInput" 
                            label="Search" 
                            variant='outlined' 
                            size='small' 
                            value={searchInput} 
                            onChange={e => setSearchInput(e.target.value)} 
                        />
                        <LoadingButton variant={"contained"}  loading={isLoading || isFetching} onClick={() => refetch()}><SearchIcon/></LoadingButton>
                    </Stack>
                )}
                {FilterMenu && (
                    <>
                    <Stack direction={"row"} sx={{display:"flex", alignItems: "center"}} spacing={1}>
                        <Tooltip title="Filter">
                            <IconButton onClick={(e) => {handleClick(e);}}>
                                <FilterAltIcon 
                                    color={searchParams.get("filterOptions") ? "primary": "inherit"}
                                />
                            </IconButton>
                        </Tooltip>

                    </Stack>
                        <Popover
                            id="filter"
                            open={filterOpen}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            >
                            <FilterMenu handleClose={handleClose}/>
                        </Popover>
                    </>
                )}
            </div>
        </div>
        );
    }

    const handleSort = (header: TableHeader) => {
        //determine sort
        //if the current sort by equals the clicked header and the current sort is asc then change to desc
        if(listParams.sortBy === header.field && listParams.sortDirection === SortDirection.Ascending) {
            setListParams({...listParams, sortDirection: SortDirection.Descending, sortBy: header.field});
        } //if the current sort by equals the clicked header and the current sort is desc then turn off sorting
        else if (listParams.sortBy === header.field && listParams.sortDirection === SortDirection.Descending){
            setListParams({...listParams, sortDirection: undefined, sortBy: undefined});
        } //Otherwise set new sort
        else  {
            setListParams({...listParams, sortDirection: SortDirection.Ascending, sortBy: header.field})
        }

        //wait until ListParams set before refetching
        setTimeout(() => {
            refetch()
        },1)
    };

    const StyledHeaderCell = styled(TableCell)(({theme}) => ({
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    }));
    const StyledHeaderLabel = styled(TableSortLabel)(({theme}) => ({
        color: theme.palette.common.white
    }));

    const StyledTable = styled(Table)(({theme}) => ({
        display: "block"  
    }));
    const StyledTableBody = styled(TableBody)(({theme}) => ({
        display: "block",
        overflowY: "scroll",
        maxHeight: props.height ?? "30rem",
        backgroundColor: theme.palette.common.white
    }));

    const Page = ({pageNum, pageSize}: {pageNum: number, pageSize: number}) => {
        const initialData = pageNum === 1 ? (firstPageData.data && firstPageData.data) : null;
        const [loadPage, setLoadPage] = useState(false)
        const [pageData, setPageData] = useState<any[] | null>(initialData)
        const pageRef = useRef(null);
        
        const {data, isError, error} = useQuery({
            queryKey: ["datatable", props.tableTitle, pageNum, searchTerm],
            queryFn: async () => {
                const response = await props.callService({...listParams, page: pageNum}, searchTerm);
                return response
            },
            enabled: loadPage,
        })
        
        useEffect(() => {
            if(data && pageNum !== 1){
                setPageData(data.data)
            }
        }, [data, pageNum])
    
        useEffect(() => {
            if(isError){
                props.setMessageItem({errorMessage: error.message})
            }
    
        }, [error, isError])

        useEffect(() => {
            const currPageRef = pageRef.current;
            
            if(pageData === null){
                const observer = new IntersectionObserver(
                    (entries) => {
                        if (entries[0].isIntersecting) {
                            setLoadPage(true);
                        }
                    }, 
                    {
                        threshold: 0.1,
                    }
                )
    
                if (pageRef.current) {
                    observer.observe(pageRef.current);
                }
    
                return () => {
                    if (observer && currPageRef) {
                        observer.unobserve(currPageRef);
                    }
                };
            }
        })

        if(pageData === null || pageData.length === 0){
            return (
                <React.Fragment>
                {Array.from({length: pageSize}).map((_, index) => (
                    <TableRow key={index} ref={index === 0 ? pageRef : null}>
                        {tableHeaders.map((header, index) => {
                            if(header.field === "statusIndicator"){
                                return (
                                    <TableCell key={index} sx={{padding: '0'}}></TableCell>
                                )
                            }
                            return (
                                <TableCell key={index} sx={{minWidth: `${header.width} !important`, maxWidth: `${header.width} !important`}}>
                                    <Skeleton variant="rounded" sx={{display:"flex", height:"2rem"}}/>
                                </TableCell>    
                            )
                        })}
                    </TableRow>
                ))}
                </React.Fragment>
            )
        }

        if(Array.isArray(pageData) && pageData.length > 0){
            return (
                <>
                    {pageData.map((row, index) => (
                        <React.Fragment key={index}>
                            {props.renderRow({data: row, index: index})}
                        </React.Fragment>
                    ))}
                </>
            )
            
        }

        return null;
        
    }

    const PaginationComponent = ({totalRecords, pageSize}: {totalRecords: number, pageSize: number}) => {
        const numPages = Math.ceil(totalRecords/pageSize)

        const pages = Array.from({length: numPages}, (_, index) => {
            const currentPageNum = index+1;
            const currentPageSize = currentPageNum === numPages ? (totalRecords % pageSize || pageSize): pageSize;
            return {pageNum: currentPageNum, pageSize: currentPageSize}
        })

        return (
            <>
                {pages.map(({pageNum, pageSize}) => (
                    <Page key={pageNum} pageNum={pageNum} pageSize={pageSize}/>
                ))}
            </>
        )
    }

    return (
        <Paper sx={{display:"inline-block"}}>
            <TableToolbar/>
            <StyledTable id="datatable" stickyHeader aria-label={`datatable-${props.tableTitle}`} sx={{maxHeight: 600, width: '100%'}}>
                <TableHead sx={{width: '100%', display: "table-header-group"}}>
                    <TableRow sx={{backgroundColor: "#233044 !important"}}>
                        {tableHeaders.map((header) => (
                            <StyledHeaderCell key={`${header.label}_${header.field}`} sx={{minWidth: `${header.width} !important`, maxWidth: `${header.width} !important`, padding: header.field === "statusIndicator" ? '0' : "1rem"}}>
                                {header.sortable ? 
                                    <StyledHeaderLabel
                                    sx={{color: "white !important"}}
                                    active={listParams.sortBy === header.field}
                                    direction={listParams.sortBy === header.field ? listParams.sortDirection : 'asc'}
                                    onClick={() => handleSort(header)}
                                    >
                                        {header.label}
                                    </StyledHeaderLabel>
                                    : <>{header.label}</>}
                            </StyledHeaderCell>
                        ))}
                        {/* used as spacer for the scroll bar so the scrollbar is within the table */}
                        <StyledHeaderCell sx={{width: "11px !important", padding: "0px !important"}}></StyledHeaderCell>
                    </TableRow>
                </TableHead>
                <StyledTableBody>
                    <PaginationComponent totalRecords={firstPageData.totalRecords} pageSize={listParams.pageSize}/>
                    {isLoading && (
                        <TableRow key={`TableLoading`}>
                            <TableCell colSpan={tableHeaders.length} sx={{width:"100%"}} >
                                <Stack direction={"row"} sx={{justifyContent: "center", width: "100%"}}>
                                    <Typography sx={{fontWeight: "800", marginRight:"1rem"}}>Loading ...</Typography>
                                    <div className='loader'/>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    )}
                    {!isLoading && firstPageData.data.length !== 0 && (
                        <TableRow key={"NoMoreRows"}>
                            <TableCell colSpan={tableHeaders.length} sx={{width:"100%"}} >
                                <Typography sx={{fontWeight: "800", marginRight:"1rem", textAlign:"center"}}>No More Rows</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {!isLoading && firstPageData.data.length === 0 && (
                        <TableRow key={"NoRowsFound"}>
                            <TableCell colSpan={tableHeaders.length} sx={{width:"100%"}} >
                                <Typography sx={{fontWeight: "800", marginRight:"1rem", textAlign:"center"}}>No Rows Found</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </StyledTableBody>
            </StyledTable>
            <Typography sx={{textAlign: 'right', padding: '0.5rem', borderTop: '1px solid #ccc'}}>
                {`Total Rows: ${firstPageData.totalRecords}`}
            </Typography>
        </Paper>
        
  )
}

export default Datatable