import React, { useState } from 'react'
import { IconButton, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';
import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import TrainingBottomNavigation from 'src/pages/User Site/TrainingDocuments/TrainingBottomNavigation/TrainingBottomNavigation';
import { useMutation, useQuery } from '@tanstack/react-query';
import { guidRegex } from 'src/Utils/helperFunc';
import LearningDocumentResponseService from 'src/Services/Training/LearningDocuments/LearningDocumentResponseService';
import TrainingSessionService from 'src/Services/Training/TrainingSessionService';
import { AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto';
import { useFormik } from 'formik';
import TraineeRow from './Components/TraineeRow';
import { LoadingButton } from '@mui/lab';
import LearningDocumentService from 'src/Services/Training/LearningDocuments/LearningDocumentService';
import TrainerRow from './Components/TrainerRow';
import { LearningDocumentResponseStatus } from 'src/dtos/Training/LearningDocumentResponse.dto';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import assets from 'src/assets';

function AttendanceSheet() {

    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const {id, responseId} = useParams(); //id is trainingSessionId
    const navigate = useNavigate();

    //#region queries
    const documentResponseQuery = useQuery({
        queryKey: ["documentResponse", responseId],
        queryFn: async () => {
            if(responseId && guidRegex.test(responseId)){
                return await LearningDocumentResponseService.Get(responseId)
            }
            return null
        }
    })

    const trainingSessionQuery = useQuery({
        queryKey: ["trainingSession", id],
        queryFn: async () => {
            if(id && guidRegex.test(id)){
                return await TrainingSessionService.Get(id)
            }
            return null
        }
    })

    const learningDocumentVersionQuery = useQuery({
        queryKey: ["learningDocumentVersion", documentResponseQuery.data?.learningDocumentVersionId],
        queryFn: async () => {
            if(documentResponseQuery.data){
                return await LearningDocumentVersionService.Get(documentResponseQuery.data.learningDocumentVersionId)
            }
            return null
        }
    })

    const learningDocumentQuery = useQuery({
        queryKey: ["learningDocument", learningDocumentVersionQuery.data?.learningDocumentId],
        queryFn: async () => {
            if(learningDocumentVersionQuery.data){
                return await LearningDocumentService.Get(learningDocumentVersionQuery.data.learningDocumentId)
            }
            return null
        }
    })

    const initialLoading = trainingSessionQuery.isLoading || documentResponseQuery.isLoading || learningDocumentVersionQuery.isLoading

    const formik = useFormik({
        enableReinitialize: true, 
        validateOnChange: false,
        initialValues: documentResponseQuery.data?.attendanceSheetResponse ?? LearningDocumentResponseService.GetDefaultValues().attendanceSheetResponse,
        onSubmit: () => {
            
        }
    })

    const submitMutation = useMutation({
        mutationFn: (id: string) => {
            return LearningDocumentResponseService.SubmitDocument(id)
        },
        onSuccess: () => {
            setMessageItem({successMessage: "Document Submitted Successfully"})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })
    
    //#endregion
     
    const handleSubmitClick = () => {
        if(documentResponseQuery.data)
            submitMutation.mutate(documentResponseQuery.data.id)
    }

    const handleBackClick = () => {
        navigate({ pathname: window.location.pathname.replace(new RegExp(`/${responseId}/Attendance`), '') });
    }
    
    const trainees = formik.values.attendees.filter(a => a.type === AttendeeType.Trainee)
    const trainers = formik.values.attendees.filter(a => a.type === AttendeeType.Trainer)

    if(initialLoading) {
        return (
            <Stack>
                {/* For title */}
                <div style={{width:"100%", display:"flex", justifyContent:"center", height:"5rem"}}>
                    <Skeleton animation="wave" variant="text" sx={{width:"80%", display:"flex"}}/>

                </div>
                {/* For Body */}
                <div style={{width:"100%", padding:"1rem"}}>
                    <Skeleton animation="wave" variant="rectangular" height={500} />
                </div>  
            </Stack>
        )
    }

    const completedStatuses = [LearningDocumentResponseStatus.Competent, LearningDocumentResponseStatus.NotYetCompetent, LearningDocumentResponseStatus.Completed,]
    const editingLocked = documentResponseQuery.data ? completedStatuses.includes(documentResponseQuery.data.status) : false
    return (
    <>
        <Stack spacing={2} sx={{marginBottom:"4rem"}}>
            <Stack direction="row" sx={{width:"100%", padding:"1rem"}}>
                <img alt="AW LOGO" src ={assets.images.logo} style={{ width: "5rem", height: "5rem" }}/>
                <Typography variant='h5' sx={{width: "100%", paddingLeft:"1rem", paddingRight:"1rem", display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>{`Attendance - ${learningDocumentQuery.data?.label}`}</Typography>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}} >
                    <IconButton onClick={handleBackClick}>
                        <ArrowBackIcon fontSize='large'/>
                    </IconButton>
                </div>
            </Stack>
            <Paper sx={{padding: "1rem"}}>
                <Typography variant='h6'>Training Topics</Typography>
                <div style={{display:"flex", flexDirection: "column", flexWrap:"wrap", gap:"0.5rem", width:"80%", maxHeight:"10rem"}}>
                    {learningDocumentVersionQuery.data && learningDocumentVersionQuery.data.topics.map((topic, index) => (
                        <Typography key={index}>{`Topic ${index+1}: ${topic.label}`}</Typography>   
                    ))}
                </div>

                <Typography variant='h6'>Trainees</Typography>
                <Typography>
                    By signing this document, I confirm that I received the above training and accept the results given regarding my readiness for assessment. I understand that additional training will be provided to me if requested by myself or the trainer before an assessment is completed. 
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Signature</TableCell>
                                <TableCell>Time Signed</TableCell>
                                <TableCell>Ready For Assesment</TableCell>
                                <TableCell>Notes/Feedback</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainees.map((trainee, index) => (
                                <TraineeRow 
                                    key={index} 
                                    formik={formik} 
                                    trainee={trainee}
                                    setMessageItem={setMessageItem}
                                    editingLocked={editingLocked}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant='h6' sx={{marginTop: "1rem"}}>Trainers</Typography>
                <Typography>As the Trainer, I sign this to acknowledge the completion of all tasks within this Trainer’s Guide. I confirm 
that I have made the decision to deem each trainee as either 'Ready' or 'Not Yet Ready' as recorded above.</Typography>
                <TableContainer component={Paper}  sx={{ minWidth: "30rem", width: "30rem" }}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Signature</TableCell>
                                <TableCell>Time Signed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainers.map((trainer, index) => (
                                <TrainerRow
                                    key={index}
                                    trainer={trainer}
                                    formik={formik}
                                    setMessageItem={setMessageItem}
                                    editingLocked={editingLocked}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography sx={{marginTop:"1rem"}}>{`Trainers have been appointed by the Training & Recruitment and ${learningDocumentQuery.data?.department?.label} Departments, they have been assessed as having current industry skills and knowledge relevant to the training being delivered. Digital training and assessing documents are owned and monitored by the Training and Recruitment Team.`}</Typography>
                <LoadingButton sx={{marginTop:"1rem"}} variant='contained' color='success' loading={submitMutation.isPending} onClick={handleSubmitClick}>Submit</LoadingButton>

            </Paper>
                        
        </Stack>
        <TrainingBottomNavigation/>
        <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>

  )
}

export default AttendanceSheet