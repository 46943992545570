import { Paper } from '@mui/material'
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import React from 'react'
import ChecklistIcon from '@mui/icons-material/Checklist';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { useNavigate } from 'react-router-dom';


function TrainingBottomNavigation() {
    const navigate = useNavigate();

    const attendanceUrl = "Attendance"

    const redirectAttendance = () => {
        if(!window.location.pathname.includes(attendanceUrl)){
            navigate(`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${attendanceUrl}`)
        }

    }

    const redirectChecklist = () => {
        if(window.location.pathname.includes(attendanceUrl)){
            navigate(`${window.location.pathname.replace(`/${attendanceUrl}`, "")}`)
        }
    }

    const handleButtonPress = (buttonIndex: number) => {
        if(buttonIndex === 0){
            redirectAttendance();
        } else {
            redirectChecklist()
        }
    }
    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
            <BottomNavigation
                showLabels
                onChange={(event, value) => handleButtonPress(value)}
            >
                <BottomNavigationAction label="Attendance" icon={<FeedOutlinedIcon/>}/>
                <BottomNavigationAction label="Checklist" icon={<ChecklistIcon/>}/>
            </BottomNavigation>
        </Paper>
    )
}

export default TrainingBottomNavigation