import { TableRow, TableCell, ListItemButton, Checkbox, Typography, Stack, Tooltip, IconButton, TextField } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import CustomSignaturePad from "src/components/SignaturePad/SignaturePad";
import { dateTimeOptions } from "src/config/constants";
import { AttendanceSheetAttendee, AttendanceSheetResponse } from "src/dtos/Training/AttendanceSheetResponse.dto";
import ReadyForAssessment from "./ReadyForAssessment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useMutation } from "@tanstack/react-query";
import TrainingSessionService from "src/Services/Training/TrainingSessionService";
import { MessageItem } from "src/components/errorHandlingSnackbar";

type Props = {
    trainee: AttendanceSheetAttendee,
    formik: FormikProps<AttendanceSheetResponse>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    readyForAssesmentColumnDisabled?: boolean;
    editingLocked: boolean;
}

function TraineeRow({formik, trainee, setMessageItem, readyForAssesmentColumnDisabled, editingLocked}:Props){
    const [outcomeVisible, setOutcomeVisible] = useState(trainee.readyForAssessment == null ? true : false)
    const [traineeNotes, setTraineeNotes] = useState(trainee.notes ?? "")

    const traineeIndex = formik.values.attendees.findIndex(attendee => attendee === trainee);
    
    const markAttended = () => {
        if(editingLocked){
            return
        }
        formik.setFieldValue(`attendees[${traineeIndex}].attended`, !trainee.attended)
    }
    const updateSignature = (signature: string | undefined) => {
        if(editingLocked){
            return
        }
        formik.setFieldValue(`attendees[${traineeIndex}].signature`, signature)
        if(signature){
            formik.setFieldValue(`attendees[${traineeIndex}].timeSigned`, new Date())
        } else {
            formik.setFieldValue(`attendees[${traineeIndex}].timeSigned`, null)
        }
    }

    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: number, values: AttendanceSheetAttendee}) => {
            return TrainingSessionService.UpdateAttendanceSheetAttendee(id, values)
        },
        onSuccess: (data) => {
            formik.setFieldValue(`attendees[${traineeIndex}]`, data.data)
            setMessageItem({successMessage: "Trainee Updated Successfully!"})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const hasMounted = useRef(false)
    useEffect(() => {
        if(hasMounted.current === true  && !editingLocked){
            updateMutation.mutate({id: trainee.id, values: trainee})
        } else {
            hasMounted.current = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainee.attended, trainee.signature, trainee.readyForAssessment, trainee.notes])
    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
                <ListItemButton
                    onClick={markAttended}
                >
                    <Checkbox 
                        edge="start"
                        checked={trainee.attended} 
                        disableRipple
                    />
                    <Typography>{`${trainee.teamMember.prefferedFirstName} ${trainee.teamMember.lastName}`}</Typography>
                </ListItemButton>
            </TableCell>
            <TableCell>
                <CustomSignaturePad 
                    person={{name: `${trainee.teamMember.prefferedFirstName} ${trainee.teamMember.lastName}`, signature: trainee.signature}}
                    setSignature={(signature: string | undefined) => updateSignature(signature)}
                    locked={editingLocked}
                />
            </TableCell>
            <TableCell>
                <Typography variant='body2' sx={{minWidth:"4rem"}}>
                    {trainee.timeSigned && new Date(trainee.timeSigned).toLocaleDateString(undefined, dateTimeOptions)}
                </Typography>
            </TableCell>
            {!readyForAssesmentColumnDisabled && (
                <TableCell>
                    <Stack direction={"row"} spacing={2}>
                        <Tooltip title={`${outcomeVisible ? "Hide" : "Show"} outcome`}>
                            <div style={{display:"flex", alignItems: "center"}}>
                            <IconButton onClick={() => setOutcomeVisible(!outcomeVisible)} size="small">
                                    {outcomeVisible ? (
                                        <VisibilityOffIcon />
                                    ) : (
                                        <VisibilityIcon/>
                                    )}
                                </IconButton>
                            </div>
                        </Tooltip>
                        {outcomeVisible && (
                            <ReadyForAssessment 
                                formik={formik}
                                trainee={trainee}
                            />
                        )}

                    </Stack>
                </TableCell>
            )}
            <TableCell>
                {outcomeVisible && (
                    <TextField
                        id={`attendees[${traineeIndex}].notes`}
                        name={`attendees[${traineeIndex}].notes`}
                        InputProps={{
                            readOnly: editingLocked,
                        }}
                        value={traineeNotes}
                        onChange={(e) => setTraineeNotes(e.target.value)}
                        onBlur={() => formik.setFieldValue(`attendees[${traineeIndex}].notes`, traineeNotes)}
                        sx={{minWidth: "12rem", width:"100%"}}
                        size='small'
                        multiline
                        rows={2}
                    />
                )}
            </TableCell>
        </TableRow>
    )
}

export default TraineeRow