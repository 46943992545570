import React, { ReactNode } from 'react';
import { Route } from "react-router-dom";
import { RouteType } from "./config";
import PageWrapper from '../components/common/PageWrapper';
import adminRoutes, { userRoutes } from './appRoutes';

const generateRoute = (routes:RouteType[]): ReactNode => {
    return routes.map((route, index) => (
        route.index ? (
            <Route
                index
                path={route.path}
                element={<PageWrapper state={route.state} pageName={route.pageName} guideLink={route.guideLink}>
                    {route.element}
                </PageWrapper>}
                key={index}
            />
        ) : (
            <Route
                path ={route.path}
                element={
                    <PageWrapper state={route.child ? undefined : route.state} pageName={route.pageName} guideLink={route.guideLink}>
                        {route.element}
                    </PageWrapper>
                }
                key={index}
                >
                    {route.child && (
                        generateRoute(route.child)
                    )}
            </Route>
        )
    ));
};

export const adminAppRoutes: ReactNode = generateRoute(adminRoutes);
export const userAppRoutes: ReactNode = generateRoute(userRoutes);
