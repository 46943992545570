/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import TrainingBottomNavigation from './TrainingBottomNavigation/TrainingBottomNavigation'
import { Button, Checkbox, IconButton, ListItemButton, Modal, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import "./styles.scss";
import { dateTimeOptions } from 'src/config/constants';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomSignaturePad from 'src/components/SignaturePad/SignaturePad';
import { useParams } from 'react-router-dom';
import { LearningDocument, LearningDocumentVersion } from 'src/dtos/Training/LearningDocument.dto';
import LearningDocumentService from 'src/Services/Training/LearningDocuments/LearningDocumentService';
import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService';
import { MessageItem } from 'src/components/errorHandlingSnackbar';

function AttendanceSheetDemo() {
    interface Trainee {
        id: number,
        name: string,
        attended: boolean,
        signature?: string,
        readyForAssesment?: boolean,
        timeSigned?: Date,
        notes?: string
    }

    interface Trainer {
        id: number,
        name: string,
        signature?: string,
        timeSigned?: Date
    }

    const exampleTrainers : Trainer[] = [
        {
            id: 1,
            name: "John Smith"
        },
        {
            id: 2,
            name: "Joe Blogs"
        }
    ]

    const exampleTrainees : Trainee[] = [
        {
            id: 1,
            name: "Sally Watson",
            attended: false,
        },
        {
            id: 2,
            name: "Vince Bishop",
            attended: false,
        },
        {
            id: 3,
            name: "Christian Terry",
            attended: false,
        },
        {
            id: 4,
            name: "Jackie Lloyd",
            attended: false,
        },
        {
            id: 5,
            name: "Jemma Moore",
            attended: false,
        },
        {
            id: 6,
            name: "Amelia Duncan",
            attended: false,
        }


    ] 
    
    const [trainers, setTrainers] = useState<Trainer[]>(exampleTrainers)
    const [trainees, setTrainees] = useState<Trainee[]>(exampleTrainees)
    const [initalLoading, setInitialLoading] = useState(true)

    const [messageItem, setMessageItem] = useState<MessageItem>({})

    const [learningDocument, setLearningDocument] = useState<LearningDocument>(LearningDocumentService.GetDefaultValues());
    const [learningDocumentVersion, setLearningDocumentVersion] = useState<LearningDocumentVersion>(LearningDocumentVersionService.GetDefaultVersionValues())

    const {id} = useParams();

    useEffect(() => {
        const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/; //check if a string is a guid
        const fetchValues = async () => {
            try {
                if(id !== undefined && guidRegex.test(id)) {
                    const data = await LearningDocumentVersionService.Get(id);
                    setLearningDocumentVersion(data);

                    const learningDocumentId = data.learningDocumentId;
                    const learningDocumentData = await LearningDocumentService.Get(learningDocumentId)
                    setLearningDocument(learningDocumentData);

                    const attendanceData = null
                    
                    
                }
            } catch (error: any) {
                setMessageItem({error: error})
            } finally {
                setInitialLoading(false)
            }
        }

        fetchValues()
    }, [id])

    const updateTrainerSignature = (signature: string | undefined, signedTrainer: Trainer) => {
        const newTrainers = trainers.map((trainer) => {
            if(trainer === signedTrainer){
                return {...trainer, signature: signature, timeSigned: new Date()}
            }
            return trainer
        })

        setTrainers(newTrainers)
    }

    const updateTraineeSignature = (signature: string | undefined, signedTrainee: Trainee) => {
        const newTrainees = trainees.map((trainee) => {
            if(trainee === signedTrainee){
                return {...trainee, signature: signature, timeSigned: new Date()}
            }
            return trainee
        })

        setTrainees(newTrainees)
    }

    
    const ReadyForAssesment = ({index}:{index: number}) => {

        const handleYesClick = () => {
            let newTrainees = [...trainees]
            if(trainees[index].readyForAssesment === true){
                newTrainees[index] = {...trainees[index], readyForAssesment: undefined}
            } else {
                newTrainees[index] = {...trainees[index], readyForAssesment: true}
            }
            setTrainees(newTrainees)
        }
    
        const handleNoClick = () => {
            let newTrainees = [...trainees]
            if(trainees[index].readyForAssesment === false){
                newTrainees[index] = {...trainees[index], readyForAssesment: undefined}
            } else {
                newTrainees[index] = {...trainees[index], readyForAssesment: false}
            }
            setTrainees(newTrainees)
        }
        
        return (
            <Stack direction="row" spacing={1} sx={{display:"flex", justifyContent: "center"}}>
                <Stack direction="row" spacing={1}>
                    
                    <Button 
                        style={{maxWidth: '3rem', maxHeight: '2.5rem', minWidth: '3rem', minHeight: '2.5rem'}}
                        size="small"
                        variant={trainees[index].readyForAssesment === true ? "contained" : "outlined"}
                        color="success" 
                        onClick={handleYesClick}
                    >
                        Yes
                    </Button>
                    <Button 
                        style={{maxWidth: '3rem', maxHeight: '2.5rem', minWidth: '3rem', minHeight: '2.5rem'}}
                        size="small"
                        variant={trainees[index].readyForAssesment === false ? "contained" : "outlined"} 
                        color="warning" 
                        onClick={handleNoClick}
                    >
                        No
                    </Button>
                </Stack>
            </Stack>
        )
    }

    const TraineeRow = ({trainee, index}:{trainee: Trainee, index: number}) => {
        const [outcomeVisible, setOutcomeVisible] = useState(true) //TODO: use this once form setup so hidden on open if value exists trainee.readyForAssesment === undefined
        
        return (
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                    <ListItemButton
                        onClick={() => {
                            let newTrainees = [...trainees]
                            newTrainees[index] = {...trainees[index], attended: !trainees[index].attended}
                            setTrainees(newTrainees)
                        }}
                    >
                        <Checkbox 
                            edge="start"
                            checked={trainee.attended} 
                            disableRipple
                        />
                        <Typography>{trainee.name}</Typography>
                    </ListItemButton>
                </TableCell>
                <TableCell>
                    <CustomSignaturePad 
                        person={trainee}
                        setSignature={(signature: string | undefined) => updateTraineeSignature(signature, trainee)}
                    />
                </TableCell>
                <TableCell>
                    <Typography variant='body2' sx={{minWidth:"4rem"}}>
                        {trainee.timeSigned && trainee.timeSigned.toLocaleDateString(undefined, dateTimeOptions)}
                    </Typography>
                    
                </TableCell>
                <TableCell>
                    <Stack direction={"row"} spacing={2}>
                        <Tooltip title={`${outcomeVisible ? "Hide" : "Show"} outcome`}>
                            <div style={{display:"flex", alignItems: "center"}}>
                                <IconButton onClick={() => setOutcomeVisible(!outcomeVisible)} size="small">
                                    {outcomeVisible ? (
                                        <VisibilityOffIcon />
                                    ) : (
                                        <VisibilityIcon/>
                                    )}
                                </IconButton>
                            </div>
                        </Tooltip>
                        {outcomeVisible && (
                            <ReadyForAssesment index={index}/>
                        )}

                    </Stack>
                </TableCell>
                <TableCell>
                    {outcomeVisible && (
                        <TextField
                            sx={{width: "14rem"}}
                            size='small'
                            multiline
                            rows={2}
                        />
                    )}
                </TableCell>
            </TableRow>
        )
    }

    if(initalLoading) {
        return (
            <Stack>
                {/* For title */}
                <div style={{width:"100%", display:"flex", justifyContent:"center", height:"5rem"}}>
                    <Skeleton animation="wave" variant="text" sx={{width:"80%", display:"flex"}}/>

                </div>
                {/* For Body */}
                <div style={{width:"100%", padding:"1rem"}}>
                    <Skeleton animation="wave" variant="rectangular" height={500} />
                </div>  
            </Stack>
        )
    }

    return (
    <>
        <Stack spacing={2} sx={{marginBottom:"4rem"}}>
            <Paper sx={{padding: "1rem"}}>
                <Typography variant='h5' sx={{display: "flex", justifyContent: "center"}}>{`Attendance - ${learningDocument.label}`}</Typography>
            </Paper>

            <Paper sx={{padding: "1rem"}}>
                <Typography variant='h6'>Training Topics</Typography>
                <div style={{display:"flex", flexDirection: "column", flexWrap:"wrap", gap:"0.5rem", width:"80%", maxHeight:"10rem"}}>
                    {learningDocumentVersion.topics.map((topic, index) => (
                        <Typography key={index}>{`Topic ${index+1}: ${topic.label}`}</Typography>   
                    ))}
                </div>

                <Typography variant='h6'>Trainees</Typography>
                <Typography>
                    By signing this document, I confirm that I received the above training and accept the results given regarding my readiness for assessment. I understand that additional training will be provided to me if requested by myself or the trainer before an assessment is completed. 
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Signature</TableCell>
                                <TableCell>Time Signed</TableCell>
                                <TableCell>Ready For Assesment</TableCell>
                                <TableCell>Notes/Feedback</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainees.map((trainee, index) => (
                                <TraineeRow key={index} index={index} trainee={trainee}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant='h6' sx={{marginTop: "1rem"}}>Trainers</Typography>
                <Typography>As the Trainer, I sign this to acknowledge the completion of all tasks within this Trainer’s Guide. I confirm 
that I have made the decision to deem each trainee as either 'Ready' or 'Not Yet Ready' as recorded above.</Typography>
                <TableContainer component={Paper}  sx={{ minWidth: "30rem", width: "30rem" }}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Signature</TableCell>
                                <TableCell>Time Signed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trainers.map((trainer) => (
                                <TableRow
                                    key={trainer.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {trainer.name}
                                    </TableCell>
                                    <TableCell>
                                        <CustomSignaturePad 
                                            person={trainer}
                                            setSignature={(signature: string | undefined) => updateTrainerSignature(signature, trainer)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {trainer.timeSigned && trainer.timeSigned.toLocaleDateString(undefined, dateTimeOptions)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography sx={{marginTop:"1rem"}}>{`Trainers have been appointed by the Training & Recruitment and ${learningDocument.department?.label} Departments, they have been assessed as having current industry skills and knowledge relevant to the training being delivered. Digital training and assessing documents are owned and monitored by the Training and Recruitment Team.`}</Typography>
                <Button sx={{marginTop:"1rem"}} variant='contained' color='success'>Submit</Button>

            </Paper>
                        
        </Stack>
        <TrainingBottomNavigation/>
        {/* <ErrorHandlingSnackbar messageItem={messageItem}/> */}
    </>

  )
}

export default AttendanceSheetDemo