import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, CircularProgressProps, Divider, Stack, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import AlertQuestion from 'src/components/questions/AlertQuestion'
import YesOrNoQuestion from 'src/components/questions/YesOrNoQuestion'
import { QuestionType, InspectionChecklistSection, QuestionDto } from 'src/dtos/Checklists/MaintenanceChecklist.dto'
import * as yup from "yup";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { ComponentResponseDto, InspectionChecklistSectionResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto'
import { Status, StatusString } from 'src/dtos/Statuses'
import { ChildEntity } from 'src/dtos/EntityChild.dto'
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService'
import { MessageItem } from 'src/components/errorHandlingSnackbar'

type Props = {
    sectionIndex: number;
    initialValues: InspectionChecklistSectionResponseDto;
    sectionContent: InspectionChecklistSection;
    percentageToPass: number; //The percentage required to pass 0-1
    updateSectionAnswers: (answers: InspectionChecklistSectionResponseDto, sectionStatus: Status) => void;
    sectionOpenable: () => true | string;
    taggedOutComponents?: ChildEntity[]; 
    setTaggedOutComponents?: React.Dispatch<React.SetStateAction<ChildEntity[]>>
    demoMode: Boolean; //If the answers are saved.
    expandedSection: string | false;
    setExpandedSection: React.Dispatch<React.SetStateAction<string | false>>;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>;
    selectedComponentsResponse?: ComponentResponseDto;
}

const validationSchema = yup.object({
    questionResponses: yup.array()
        .of(yup.object().shape({
            answer: yup
                .string(),
            failureCause: yup
                .string()
                .min(10, "Failure cause is required and must be at least 10 characters"),
            remidialAction: yup
                .string()
                .min(10, "Remidial action is required and must be at least 10 characters")
                .notRequired(),
            issueResolved: yup
                .boolean()
                .nullable(),
            actionRequired: yup
                .string()
                .min(10, "Action required is required and must be at least 10 characters")
                .notRequired(),
            comment: yup
                .string()
                .max(255, "Comment cannot be more than 255 characters"),
            groupedQuestions: yup.array()
                .of(yup.object().shape({
                    failureCause: yup
                        .string()
                        .min(10, "Failure cause is required and must be at least 10 characters"),
                    remidialAction: yup
                        .string()
                        .min(10, "Remidial action is required and must be at least 10 characters")
                        .notRequired(),
                    issueResolved: yup
                        .boolean()
                        .nullable(),
                    actionRequired: yup
                        .string()
                        .min(10, "Action required is required and must be at least 10 characters")
                        .notRequired(),
                        comment: yup
                .string()
                .max(255, "Comment cannot be more than 255 characters"),
                }))
                
    }))
    .required()
})

function FormSection({sectionIndex, initialValues, sectionContent, percentageToPass, updateSectionAnswers, sectionOpenable, taggedOutComponents, setTaggedOutComponents, demoMode, expandedSection, setExpandedSection, setMessageItem, selectedComponentsResponse}: Props) {
    const accordionRef = useRef<HTMLDivElement | null>(null);

    const saveSection = (values: any) => {

    }

    

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: initialValues,
        onSubmit: (values) => {
            saveSection(values);
        }
    })

    const questionVisible = (question: QuestionDto) : boolean => {
        if(question.repeatOnComponentSetUserSelected === true && selectedComponentsResponse && question.relatedComponent && question.relatedComponent.children){
            return selectedComponentsResponse.children.some(e => e.relatedComponent.id === question.relatedComponent?.id);
        } else {
            return true;
        }
    }

    const [questionVisibility, setQuestionVisibility] = useState<boolean[]>(() => sectionContent.questions.map(question => questionVisible(question)))


    useEffect(() => {
        const newQuestionVisibility = sectionContent.questions.map(question => questionVisible(question));
        setQuestionVisibility(newQuestionVisibility)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionContent.questions, selectedComponentsResponse?.children])
    
    const numberOfQuestions = formik.values.questionResponses.filter((answer) => answer.status !== Status.NA).length
    const passedQuestions = formik.values.questionResponses.filter((answer) => answer.status === Status.Passed).length    
    const failedQuestions = formik.values.questionResponses.filter((answer) => answer.status === Status.Failed).length
    const sectionFailed = Boolean(failedQuestions > (numberOfQuestions - percentageToPass*numberOfQuestions))
    const sectionPassed = Boolean(sectionFailed === false && formik.values.questionResponses.filter((answer => answer.status === Status.Unanswered)).length === 0)
    const sectionUnanswered = Boolean((failedQuestions + passedQuestions) === 0)

    //Update section Status
    const updateSection = () => {
        const sectionStatus: Status = sectionFailed ? Status.Failed : sectionPassed ? Status.Passed : sectionUnanswered ? Status.Unanswered : Status.Inprogress
        const updateSection = async () => {
            try {
                await InspectionChecklistResponseService.UpdateSection(formik.values.id, formik.values)

            } catch (error: any){
                console.log(error)
            }
        }
        if(formik.values.status !== sectionStatus){
            formik.setFieldValue("status", sectionStatus)
            if(!demoMode){
                updateSection();
            }
        }

        updateSectionAnswers(formik.values, sectionStatus)
    }

    function QuestionProgress(
        props: CircularProgressProps & { value: number }
    ) {
        const progressColour = passedQuestions === numberOfQuestions ? "success" : sectionFailed ? "error" : "primary";
        return (
            <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress variant="determinate" {...props} color={progressColour} size="3rem"/>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >{`${passedQuestions}/${numberOfQuestions}`}</Typography>
                </Box>
            </Box>
        );
    }

    const handleSectionOpen =
        (section: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            
            const sectionOpenableResponse = sectionOpenable();
            if (demoMode || sectionOpenableResponse === true) {
                setExpandedSection(isExpanded ? section : false)
            } else {
                setMessageItem({ errorMessage: sectionOpenableResponse});
            }
        }; 

    return (
        <div>
            <Accordion ref={accordionRef} expanded={expandedSection === `${sectionContent.label}_${sectionIndex}`} onChange={handleSectionOpen(`${sectionContent.label}_${sectionIndex}`)}>
                <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls={`${sectionContent.label}-content`}
                id={`${sectionContent.label}-header`}
                >
                <Stack direction="row" sx={{ width: "95%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Typography variant='h5'>{sectionContent.label}</Typography>
                    <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center"}}>
                        <Typography>{StatusString[formik.values.status]}</Typography>

                        {failedQuestions > 0 && (
                            <ErrorOutlineOutlinedIcon sx={{color:"red"}} fontSize='large'/>
                        )}
                        {passedQuestions > 0 &&
                        <QuestionProgress 
                            value={(passedQuestions/numberOfQuestions)*100}  
                        />
                        }          
                    </Stack>
                    
                </Stack>
                </AccordionSummary>
                <Divider/>
                <AccordionDetails>
                <Typography variant='body1' sx={{paddingLeft:"1rem"}}>{sectionContent.sublabel}</Typography>
                <Stack spacing={2}>
                {sectionContent.questions.map(
                    (question, questionIndex) =>{
                        return(
                            <React.Fragment key={questionIndex}>
                                {questionVisibility[questionIndex] && (
                                    (question.type === QuestionType.YesOrNo && (
                                        <YesOrNoQuestion
                                            key={questionIndex}
                                            formik={formik}
                                            questionIndex={questionIndex}
                                            questionDetails={question}
                                            taggedOutComponents={taggedOutComponents}
                                            setTaggedOutComponents={setTaggedOutComponents}
                                            demoMode={demoMode}
                                            updateSection={updateSection}
                                            />
                                    )) ||
                                    (question.type === QuestionType.Alert && (
                                        <AlertQuestion
                                            key={questionIndex}
                                            questionTitle={question.label}
                                            />
                                    ))
                                )}
                                
                            </React.Fragment>
                        )
                        
                    }
                        
                    )
                }

                </Stack>
                </AccordionDetails>
            </Accordion>
        </div>
        
    )
    
}

export default FormSection